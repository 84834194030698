import React from "react";
import style from "./customTooltip.module.css";
import CopyEmail, { CopyMobile } from "../../../GenericComponent/CopyEmailFeature/CopyEmail";

function isValidEmail(email) {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
}
const CustomTooltip = ({
  index,
  value,
  limit,
  styles,
  tooltipstyle,
  onClick,
}) => {
  const showtooltip = (id, name, type, size) => {
    if (type === "SHOW") {
      if (name?.length > size) {
        document.getElementById(id).style.visibility = "visible";
      }
    } else {
      if (name?.length > size) {
        document.getElementById(id).style.visibility = "hidden";
      }
    }
  };

  return (
    <>
      <p
        onMouseOver={() => showtooltip(index, value, "SHOW", limit)}
        onMouseOut={() => showtooltip(index, value, "HIDE", limit)}
        style={styles}
        onClick={onClick}
      >
        {value?.length > limit ? value?.substr(0, limit) + "..." : value}
        <span
          id={index}
          className={style.tooltiptext}
          style={{
            visibility: "hidden",
            margin: "0 0 0 0",
            ...tooltipstyle,
          }}
        >
          {value}
        </span>
        {isValidEmail(value) && <CopyEmail email={value} />}
      </p>
    </>
  );
};

export default CustomTooltip;
export const CustomTooltipDiv = ({
  index,
  value,
  limit,
  styles,
  tooltipstyle,
  onClick,
}) => {
  const showtooltip = (id, name, type, size) => {
    if (type === "SHOW") {
      if (name?.length > size) {
        document.getElementById(id).style.visibility = "visible";
      }
    } else {
      if (name?.length > size) {
        document.getElementById(id).style.visibility = "hidden";
      }
    }
  };

  return (
    <>
      <div
        onMouseOver={() => showtooltip(index, value, "SHOW", limit)}
        onMouseOut={() => showtooltip(index, value, "HIDE", limit)}
        style={styles}
        onClick={onClick}
      >
        {value?.length > limit ? value?.substr(0, limit) + "..." : value}
        <span
          id={index}
          className={style.tooltiptext}
          style={{
            visibility: "hidden",
            margin: "0 0 0 0",
            ...tooltipstyle,
          }}
        >
          {value}
        </span>
        {isValidEmail(value) && <CopyEmail email={value} />}
      </div>
    </>
  );
};

export const EmailTooltip = ({
  index,
  value,
  limit,
  styles,
  tooltipstyle,
}) => {
  const showtooltip = (id, name, type, size) => {
    if (type === "SHOW") {
      if (name?.length > size) {
        document.getElementById(id).style.visibility = "visible";
      }
    } else {
      if (name?.length > size) {
        document.getElementById(id).style.visibility = "hidden";
      }
    }
  };

  return (
    <>
      <div
        styles={styles}
        className={style.emailTooltipContainer}
      >
        <div
          className={style.tooltipEmail}
          onMouseOver={() => showtooltip(index, value, "SHOW", limit)}
          onMouseOut={() => showtooltip(index, value, "HIDE", limit)}
        >{value?.length > limit ? value?.substr(0, limit) + "..." : value}</div>
        {isValidEmail(value) && <CopyEmail email={value} />}
      </div>

      <div
        id={index}
        className={style.tooltiptextNew}
        style={{
          visibility: "hidden",
          margin: "0 0 0 0",
          whiteSpace: "normal", 
          wordWrap: "break-word",
          maxWidth: "200px",
          ...tooltipstyle,
        }}
      >
        {value}
      </div>
    </>
  );
};

export const MobileTooltip = ({
  value,
  styles,
}) => {

  return (
    <>
      <div
        styles={styles}
        className={style.emailTooltipContainer}
      >
        <div
          className={style.tooltipEmail}
        >{value}</div>
        <CopyMobile number={value} />
      </div>

    </>
  );
};