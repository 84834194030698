import React, { useState, useEffect } from 'react';
import style from './groups.module.css';
import SideBar from '../commonComponent/SideBar';
import { useHistory } from 'react-router-dom';
import constants from '../../../Utils/constants';
import axios from 'axios';
import loaderImg from "./../../../assets/loader-green2.gif";
import cookie from 'react-cookies';
import { Toastify, ToastifyError } from '../../../App';
import { employeeId } from '../../../Utils/constants';
import crown from "../../../assets/Crown-Owner.png";
import Modal from 'react-responsive-modal';
import { addSpace } from '../taskTrackerAPIs/taskTrackerAPI';
import { useMutation } from 'react-query';

const Groups = () => {
  const url2 = constants.apiBaseUrl2;

  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [GroupsList, setgroupList] = useState([]);
  const [openSpaceModal, setOpenSpaceModal] = useState(false);
  const [spaceName, setSpaceName] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [validate, setValidate] = useState(false);

  const viewListHandler = (groupId,group, groupMember, isAdmin) => {
    if(groupMember){
      history.push({
        pathname: "/GroupWiseList",
        state: {
          groupId:groupId,
          Groups:group,
          isAdmin: isAdmin,
          isActive: isActive,
        },
      });
    }
    else{
      ToastifyError("You're not a member!");
    }
  };

  const getGroupDashboardList = () => {
    setIsLoaded(true);
    axios
      .get(url2 + `/groupDashboard?active=${isActive}&empId=${employeeId}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: ` ${cookie.load('token')}`,
        },
      })
      .then((res) => {
        if(res.data.responseData?.message){
          ToastifyError(res.data.responseData?.message)
          setIsLoaded(false);
        } else{
          setgroupList(res.data.responseData);
          setIsLoaded(false);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getGroupDashboardList();
  }, [isActive]);

  const editSpaceMutation = useMutation(addSpace, {
    onSuccess: (res)=>{
      if(res?.statusCode){
        res?.responseData?.message[0]==='G' ? ToastifyError("Space Name Already Taken!") : ToastifyError(res?.responseData?.message);
      }
      else{
        Toastify(res?.responseData);
        getGroupDashboardList();
      }
    },
    onError: ()=>{
      Toastify("Something Went Wrong!");
    }
  })

  return (
    <>
      <div className={style.mainSection}>
        <SideBar />

        <div className={style.rightSection}>
          <div className={style.grpus}>
            <div>Spaces</div>
            <div style={{display: "flex", alignItems: "center"}}>
              <input
                  type="checkbox"
                  className={style.showAllCheckBox}
                  style={{
                      visibility: "visible",
                      accentColor:"#65A15E",
                      transform: "translateY(2px)",
                      margin: "0px 4px 2px 0px"
                  }}
                  checked={!isActive}
                  onChange={()=>setIsActive(prev=>!prev)}
              />
              <label>Show Closed Spaces</label>
            </div>
            <button className={style.addSpaceBtn} onClick={()=>setOpenSpaceModal(true)}>Add Space</button>
          </div>
          <div className={style.taskAssigned}>
          {isLoaded ? (
            <div>
              <img src={loaderImg} alt="loader" className={style.loader} />
              <p className={style.loaderPara}>Loading...</p>
            </div>
          ) : (
            
          
            GroupsList?.length===0 && !isLoaded ? (
              <p className={style.noDataFound} >No Data Available.</p>
            ) : (
                <div className={style.rowAlign}>
                  {GroupsList?.map((group) => (
                      <div className={style.droupsData} onClick={() => viewListHandler(group.groupId, group?.groupName, group?.groupMember, group?.isAdmin)}>
                        <div className={style.imgContainer}>{group?.isAdmin && <img src={crown} height="25px" width="30px" className={style.crownImg} />}</div>
                        <div className={style.groupName}>
                          <div className={style.groupNameChild} title={group?.groupName}>{group?.groupName}</div>
                        </div>
                        <div style={{height: "2px"}}></div>
                        <div className={style.countData}>
                          <div className={style.count1}>
                            <span>{group?.countByMembers}</span>
                            <span>Members</span>
                          </div>
                          <div className={style.count2}>
                            <span>{group?.countByTasks}</span>
                            <span>Tasks</span>
                          </div>
                        </div>
                      </div>
                  ))}
                </div>
            )
          )}
          </div>
        </div>
      </div>
      <Modal
        open={openSpaceModal}
        onClose={()=>{setSpaceName(''); setOpenSpaceModal(false); setValidate(false)}}
        center
      >
        <>
          <div className={style.memberModalHeading} style={{width: "18rem", marginBottom: "2rem"}}>Add Space</div>
          <div className={style.addMembers} style={{marginBottom: "5px"}}>Space Title <span style={{color: 'red'}}>*</span></div>
          <input type="text" style={{width: "100%", padding: "0px 2px"}} value={spaceName} onChange={(e)=>{setValidate(false); setSpaceName(e.target.value)}}/>
          <p className={validate ? style.errorText : style.errorTextHidden}>Please Enter Space Title</p>
          <div 
            style={{width : "100%",marginTop: "4rem", display: "flex", justifyContent: "center"}}
          >
            <button className={style.doneButton}
              onClick={()=>{
                if(spaceName?.trim()?.length>0){
                  if(!editSpaceMutation?.isLoading) editSpaceMutation.mutate({spaceName : spaceName });
                  setOpenSpaceModal(false);
                }
                else {
                  setValidate(true);
                }
                setSpaceName('');
              }}
            >Save
            </button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default Groups;
