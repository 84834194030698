import React, { useState, useEffect } from "react";
import style from "./myTasks.module.css";
import filterimg from "./../../../assets/filter-results-button.png";
import rubbishBinImg from "./../../../assets/rubbish-bin.png";
import pencilEditButtonImg from "./../../../assets/pencil-edit-button.png";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import Polygon from "../../../assets/Polygon.png";
import sortDownarrowImg from "./../../../assets/Down.png";
import sortUparrowImg from "./../../../assets/Up.png";
import Iconmetrocross from "../../../assets/Iconmetrocross.png";
import Iconionicmdcheckbox from "../../../assets/Iconionicmdcheckbox.png";
import Iconfeatherfilter from "../../../assets/Iconfeatherfilter.png";
import Iconionicmdclosecircle from "../../../assets/Iconionicmdclosecircle.png";
import milestone from "../../../assets/milestone.png";
import downarrow from "../../../assets/downarrow.png";
import Pagination from "../commonComponent/Pagination";
import Filter from "./component/Filter";
import { useHistory } from "react-router-dom";
import deleteTaskComment from "../taskAssignedByMe/component/deleteTaskComment";
import EditTask from "../taskAssignedByMe/component/EditTask";
import loaderImg from "../../../assets/loader-green2.gif";
import Moment from "moment";
import axios from "axios";
import cookie from "react-cookies";
import constants, { employeeId } from "../../../Utils/constants";
import useValidationAccess from "../../custumHooks/useValidationAccess";
import { taskTrackerAccessCode } from "../../../Utils/constants";
import SideBar from "../commonComponent/SideBar";

const ary = [
  {
    a: "01",
    b: "Database Creation",
    c: "yyu ytujgj jhgj hjg ff",
    d: "Shailly Tyagi",
    e: "Assigned",
    f: "Low",
    g: "20 July(Fri)",
  },
];
const arr = [
  {
    a: "20 July(Fri)",
    b: "Shailly Tyagi",
    c: "Assigned",
    d: "Low",
  },
];

const MyTasks = (props) => {
  const [tasks, setTasks] = useState([]);
  const [tasksRecurring, setTasksRecurring] = useState([])
  const [sort, setSort] = useState("DESC")
  const [sort1, setSort1] = useState("DESC")
  const [sortBy, setSortBy] = useState("")
  const [sortBy1, setSortBy1] = useState("")
  const [totalTasks, settotalTasks] = useState(0);
  const [totalTasksRecurring, settotalTasksRecurring] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [pageCountRecurring, setpageCountRecurring] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageRecurring, setCurrentPageRecurring] = useState(1);
  const [currentPageRecurring1, setCurrentPageRecurring1] = useState(1);
  const [applied, setApplied] = useState(false);
  const [data, setData] = useState();
  const [assigned, setAssigned] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [planned, setPlanned] = useState([]);
  const [planedEndDateOrder, setPlanedEndDateOrder] = useState(null);
  const [creationDateOrder, setCreationDateOrder] = useState(null);
  const [creationDateOrder1, setCreationDateOrder1] = useState(null);
  const [priority, setPriority] = useState([]);
  const [priorityOrder, setPriorityOrder] = useState(null);
  const [status, setStatus] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoaded1, setIsLoaded1] = useState(false);
  const [allData, setAllData] = useState({
    assignedBy: assigned,
    creationDateOrder1: creationDateOrder1,
    creationDateOrder: creationDateOrder,
    planedEndDateOrder: planedEndDateOrder,
    priority: priority,
    priorityOrder: priorityOrder,
    status: status,
    functionalAreaId: [],
  });
  let isScroll = true;
  const limit = 10;
  const activePage = 1;
  const history = useHistory();
  const { checkValidateAccess } = useValidationAccess();

  const getaskAssignedByFilterData = () => {
    axios
      .get(url2 + "getaskAssignedToFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setData(res.data.responseData.employeeNames);
      })
      .catch(() => { });
  };

  const url = constants.apiBaseUrl;
  const url2 = constants.apiBaseUrl2;
  const handleapidata = () => {
    setIsLoaded(true);
    const historyData = axios
      .get(url2 + "/getRecurringTaskAssignedByMe?limit=" + limit + "&page=" + currentPage + "&Status=" + isChecked + "&sort=" + sort1 + "&sortBy=" + sortBy1, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,

        },
      })
      .then((res) => {
        setTimeout(() => {
          setIsLoaded(false);
        }, 250);
        if (res.data.statusCode == 0) {
          const responseData = res.data.responseData;
          setpageCount(responseData.totalNumberOfPagesAsPerGivenPageLimit);
          setTasks(responseData.tasks);
          settotalTasks(responseData.totalRecurringTask);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const handleaRecurringTask = () => {
    setIsLoaded1(true);
    const historyData = axios
      .get(url2 + "/getMyRecurringTask?limit=" + limit + "&page=" + currentPageRecurring + "&Status=" + isChecked + "&sort=" + sort + "&sortBy=" + sortBy, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
          isChecked
        },
      })
      .then((res) => {
        setTimeout(() => {
          setIsLoaded1(false);
        }, 250);
        if (res.data.statusCode == 0) {
          const responseData = res.data.responseData;
          setpageCountRecurring(responseData.totalNumberOfPagesAsPerGivenPageLimit);
          setTasksRecurring(responseData.tasks);
          settotalTasksRecurring(responseData.totalRecurringTask);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const geyEmployeeList = () => {
    const employeeData = axios
      .get(url2 + "/getMytaskFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.statusCode == 0) {
          setUserLists(res.data.responseData.employeeNames);
        }
      })
      .catch((err) => {
        return err;
      });
  };


  useEffect(() => {
    handleapidata();
  }, [creationDateOrder, currentPage, isChecked, sortBy1, sort1]);

  useEffect(() => {
    handleaRecurringTask();
  }, [currentPageRecurring, creationDateOrder1, isChecked, sortBy, sort])


  const handleApplied = (arg) => {
    setCurrentPage(1);
    if (assigned.length !== 0 || priority.length !== 0 || status.length !== 0) {
      setApplied(true);
    }
    setShowFilter(false);
    handleapidata();
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  const handleTask = () => {
    history.push("/taskTracker/recurringTaskCreate");
  };

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  const handlePageChangeRecurring = (page, status) => {
    if (status == "next") {
      setCurrentPageRecurring(page + 1);
    } else if (status == "prev") {
      setCurrentPageRecurring(page - 1);
    }
  };

  const handlePageChangeRecurring1 = (page, status) => {
    if (status == "next") {
      setCurrentPageRecurring1(page + 1);
    } else if (status == "prev") {
      setCurrentPageRecurring1(page - 1);
    }
  };

  const closeFilter = () => {
    setAllData((preValue) => {
      return preValue
        ? {
          ...preValue,
          assignedBy: [],
          priority: [],
          status: [],
          functionalAreaId: [],
        }
        : null;
    });
    setAssigned([]);
    setPriority([]);
    setStatus([]);
    setShowFilter(false);
    setApplied(false);
  };

  const handleView = (arg) => {
    history.push({
      pathname: "/taskTracker/recurringTaskDetails",
      state: {
        task: arg,
        activePage: 1,
      },
    });
  };

  const handleViewAssignedToMe = (arg) => {
    history.push({
      pathname: "/taskTracker/recurringTaskDetailsAssignedToMe",
      state: {
        task: arg,
        activePage: 1,
      },
    });
  };


  function formatDate(dateString) {
    return dateString.split(' ')[0] + ' ' + dateString.split(' ')[1];
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSort = (field) => {
    setSortBy1(field);
    if (sort1 === "ASC") {
      setSort1("DESC")
    } else {
      setSort1("ASC")
    }
  };


  const renderSortArrow = (field) => {
    if (sortBy1 !== field) return null;

    return sort1 === "ASC" ? (
      <TiArrowSortedUp
        style={{
          cursor: "pointer",
          color: "#414448",
        }}
      />
    ) : (
      <TiArrowSortedDown
        style={{
          cursor: "pointer",
          color: "#414448",
        }}
      />
    );

  };


  const handleSort1 = (field) => {


    setSortBy(field);
    if (sort === "ASC") {
      setSort("DESC")
    } else {
      setSort("ASC")
    }
  };


  const renderSortArrow1 = (field) => {

    if (sortBy !== field) return null;

    return sort === "ASC" ? (
      <TiArrowSortedUp
        style={{
          cursor: "pointer",
          color: "#414448",
        }}
      />
    ) : (
      <TiArrowSortedDown
        style={{
          cursor: "pointer",
          color: "#414448",
        }}
      />
    );


  };

  return (
    <>
      <div className={style.mainSection}>
        <SideBar />
        {checkValidateAccess(
          taskTrackerAccessCode?.my_task_menu
        ) && (<div className={style.taskTracker} style={{
          overflowY: "auto",
          scrollbarWidth: "none",
        }}>
          <div className={style.header}>
            <div>
              <div style={{ fontSize: '18px', fontWeight: '600' }}>Recurring Tasks</div>
              <div style={{ marginLeft: "20px", display: 'flex', justifyContent: 'center' }}>
                <input
                  style={{ visibility: 'visible', accentColor: "green", marginLeft: "10px", height: '20px', width: '20px' }}
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <span style={{ marginLeft: '10px' }}>Show All</span> <span style={{ marginLeft: '5px', color: 'red' }}>(Include Closed Task)</span>
              </div>
            </div>
            {/* <button className={style.filter} onClick={handleFilter}>
              {applied && (
                <img className={style.appliedcheck} src={Iconionicmdcheckbox} />
              )}
              <img src={filterimg} />
              <span>Filter</span>
            </button> */}

            {showFilter && <img className={style.polygon} src={Polygon} />}
            <div>
              {checkValidateAccess(taskTrackerAccessCode?.create_Task_MyTask) && (
                <button onClick={handleTask} className={style.createTask}>
                  <img
                    style={{ width: "15px", marginRight: "10px" }}
                    alt="Iconmetro"
                    src={Iconmetrocross}
                  />
                  <span>Create Recurring Task</span>
                </button>
              )}
            </div>

            <div className={style.mobileHeader}>
              <p>
                <img onClick={handleFilter} src={Iconfeatherfilter} />
              </p>
              <p style={{ backgroundColor: "#65A15E" }}>
                <img onClick={handleTask} src={Iconmetrocross} />
              </p>
            </div>
          </div>
          <div >
            <div style={{ marginLeft: "25px", display: 'flex' }}>
              <p style={{
                fontWeight: "500", display: "flex",
                fontSize: '1rem',
                // justifyContent: "center",
                alignItems: "center",
                width: '170px'
              }}>Task Assigned By Me</p>
              <p
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #65a15e",
                  marginLeft: "10px"
                }}
              >
                <span>{tasks ? totalTasks : 0}</span>
              </p>
            </div>
            <div
              className={style.container}
              id="div"
              onClick={() => {
                setShowFilter(false);
              }}
            >

              {isLoaded ? (
                <div>
                  <img src={loaderImg} alt="loader" className={style.loader} />
                  <p style={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: 'green',
                    fontSize: '26px',
                    fontWeight: '500'
                  }}>Loading...</p>
                  {/* <p className={style.loaderPara}>Loading...</p> */}
                </div>
              ) : (
                ""
              )}
              {tasks.length > 0 && !isLoaded ? (
                <div>
                  <div
                    className={style.scrollAddTH}
                  >
                    <table>
                      <thead
                      >
                        <tr style={{cursor:'pointer',textDecoration:'underline'}} className={style.th}>
                          <th
                            onClick={() => handleSort("id")}
                            style={{ width: "3rem", fontSize: "15px", color: '#414448' }}
                          >
                            Task{renderSortArrow("id")}<br></br>ID

                          </th>

                          <th
                            onClick={() => handleSort("created")}
                            style={{ width: "5rem", fontSize: "15px", color: '#414448' }}
                          >
                            Creation
                            {renderSortArrow("created")}
                            <br></br> Date

                          </th>
                          <th onClick={() => handleSort("title")} style={{ width: "40rem", fontSize: "15px", color: '#414448' }}>
                            Task Title
                            {renderSortArrow("title")}

                          </th>

                          <th onClick={() => handleSort("assignedTo")} style={{ width: "7rem", fontSize: "15px", color: '#414448' }}>
                            Assigned To
                            {renderSortArrow("assignedTo")}
                          </th>

                          <th onClick={() => handleSort("frequency")} style={{ width: "6rem", fontSize: "15px", color: '#414448', textAlign: 'center' }}>
                            {" "}
                            Frequency
                            {renderSortArrow("frequency")}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>

                  <div className={style.scrollAdd}>
                    <table>
                      <tbody>
                        {tasks &&
                          tasks.map((task, index) => (
                            <tr className={style.td} key={index}>
                              <td
                                style={{ width: "3rem", fontSize: '15px', fontWeight: '400' }}
                              >
                                {task.taskId}</td>
                              <td
                                style={{ width: "5rem", fontSize: '15px', fontWeight: '400' }}
                              >
                                {formatDate(task.created)}
                              </td>
                              <td
                                style={{ width: "40rem", fontSize: '15px', fontWeight: '700', textDecoration: 'underline', cursor: "pointer", color: task.status === "Closed" ? "red" : "#65A15E" }}
                                onClick={() => handleView(task)}
                                title={task?.title}
                              >
                                {task?.title.length > 83 ? `${task?.title.substring(0, 83)}...` : task?.title}
                              </td>
                              <td
                                style={{ width: "7rem", fontSize: '15px', fontWeight: '400' }}
                                title={task?.name}
                              >
                                {task?.name.length > 22 ? `${task?.name.substring(0, 22)}...` : task?.name}
                              </td>
                              <td
                                style={{ width: "6rem", fontSize: '15px', textAlign: 'center' }}
                              >
                                {task.frequency}
                                <span style={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  marginLeft: '1px'
                                }}><br></br>{
                                    task.frequency === "Weekly" ? `(${task?.frequencyValue?.substring(0, 3)})` :
                                      task.frequency === "Monthly" ? `(${task?.frequencyValue?.split('-')[0]})` :
                                        task.frequency === "Fortnightly" ? `(${task?.frequencyValue.split(' ').join('')})` :
                                          task.frequency === "Quarterly" ? `(${task?.frequencyValue?.split('-')[0]})` : null
                                  }</span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      {/* </div> */}
                    </table>
                  </div>
                </div>

              ) : (
                <div className={style.noTasks}>
                  {!isLoaded ? (
                    <div style={{ display: "block" }}>
                      <img src={milestone} alt="No data found" />
                      <h2>No Task Assigned Yet</h2>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}



              <div className={style.mobileContainer}>
                {arr.map((ar, indexa) => {
                  return (
                    <div
                      key={indexa}
                      style={{
                        display: "block",
                        borderBottom: "1px solid #0000001A",
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <strong>{indexa + 1}. Database Creation</strong>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Assigned By :
                        </span>
                        <span style={{ fontSize: "16px", opacity: ".7" }}>
                          {" " + ar.b}
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Status :
                        </span>
                        <span
                          style={{
                            color: "#FCB902",
                            fontSize: "16px",
                            fontWeight: "300",
                          }}
                        >
                          {" " + ar.c}
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Priority :
                        </span>
                        <span style={{ fontSize: "16px", opacity: ".7" }}>
                          {" " + ar.d}
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Planned End Date :
                        </span>
                        <span style={{ fontSize: "16px", opacity: ".7" }}>
                          {" " + ar.a}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div>
              {totalTasks > limit && pageCount > 0 && (
                <div
                  // className={style.paginationmobileContainer}
                  style={{ marginRight: "1.3rem", marginTop: "20px" }}
                >
                  <Pagination
                    pagesCount={pageCount}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                  />
                </div>
              )}
            </div>

            <div style={{ marginLeft: "25px", display: "flex" }}>
              <p style={{
                fontWeight: "500", display: "flex", fontSize: '1rem',
                // justifyContent: "center",
                alignItems: "center",
                width: '170px'
              }}>Task Assigned To Me</p>
              <p
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #65a15e",
                  marginLeft: "10px"
                }}
              >
                <span>{tasksRecurring ? totalTasksRecurring : 0}</span>
              </p>
            </div>
            <div
              className={style.container}
              id="div"
              onClick={() => {
                setShowFilter(false);
              }}
            >

              {isLoaded1 ? (
                <div>
                  <img src={loaderImg} alt="loader" className={style.loader} />
                  <p style={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: 'green',
                    fontSize: '26px',
                    fontWeight: '500'
                  }}>Loading...</p>
                  {/* <p className={style.loaderPara}>Loading...</p> */}
                </div>
              ) : (
                ""
              )}
              {tasksRecurring.length > 0 && !isLoaded1 ? (
                <div>
                  <div className={style.scrollAddTH}>
                    <table>
                      <thead>
                        <tr style={{textDecoration:'underline'}} className={style.th}>
                          <th
                            onClick={() => handleSort1("id")}
                            style={{ width: "3rem", fontSize: "15px", color: '#414448', cursor: 'pointer' }}
                          >
                            Task{renderSortArrow1("id")}<br></br>ID

                          </th>

                          <th
                            onClick={() => handleSort1("created")}
                            style={{ width: "5rem", fontSize: "15px", color: '#414448', cursor: 'pointer' }}
                          >
                            Creation
                            {renderSortArrow1("created")}
                            <br></br> Date
                          </th>
                          <th
                            onClick={() => handleSort1("title")}
                            style={{ width: "40rem", fontSize: "15px", color: '#414448', cursor: 'pointer' }}
                          >
                            Task Title
                            {renderSortArrow1("title")}
                          </th>

                          <th
                            onClick={() => handleSort1("assignedBy")}
                            style={{ width: "7rem", fontSize: "15px", color: '#414448', cursor: 'pointer' }}
                          >
                            Assigned By
                            {renderSortArrow1("assignedBy")}
                          </th>
                          <th
                            onClick={() => handleSort1("frequency")}
                            style={{ width: "6rem", fontSize: "15px", color: '#414448', textAlign: 'center', cursor: 'pointer' }}
                          >
                            {" "}
                            Frequency
                            {renderSortArrow1("frequency")}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>

                  <div className={style.scrollAdd}>
                    <table>
                      <tbody>
                        {tasksRecurring &&
                          tasksRecurring.map((task, index) => (
                            <tr className={style.td} key={index}>
                              <td
                                style={{ width: "3rem", fontSize: '15px' }}
                              >{task.taskId}</td>
                              <td
                                style={{ width: "5rem", fontSize: '15px' }}
                              >
                                {formatDate(task?.created)}
                                {/* {task.created} */}
                              </td>
                              <td
                                style={{ width: "40rem", fontWeight: '700', fontSize: '15px', textDecoration: 'underline', cursor: "pointer", color: task.status === "Closed" ? "red" : "#65A15E" }}
                                onClick={() => handleViewAssignedToMe(task)}
                                title={task?.title}
                              >
                                {task?.title.length > 83 ? `${task?.title.substring(0, 83)}...` : task?.title}
                              </td>
                              {/* <td className={style.desc} >{task.description}</td> */}
                              <td
                                style={{ width: "7rem", fontSize: '15px' }}
                              >
                                {task?.name.length > 22 ? `${task?.name.substring(0, 22)}...` : task?.name}
                              </td>

                              <td
                                style={{ width: "6rem", fontSize: '15px', textAlign: 'center' }}
                              >
                                {task.frequency}
                                <span style={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  marginLeft: '1px'
                                }}><br></br>{
                                    task.frequency === "Weekly" ? `(${task?.frequencyValue?.substring(0, 3)})` :
                                      task.frequency === "Monthly" ? `(${task?.frequencyValue?.split('-')[0]})` :
                                        task.frequency === "Fortnightly" ? `(${task?.frequencyValue.split(' ').join('')})` :
                                          task.frequency === "Quarterly" ? `(${task?.frequencyValue?.split('-')[0]})` : null
                                  }</span>

                              </td>
                            </tr>
                          ))}
                      </tbody>
                      {/* </div> */}
                    </table>
                  </div>
                </div>
              ) : (
                <div className={style.noTasks}>
                  {!isLoaded1 ? (
                    <div style={{ display: "block" }}>
                      <img src={milestone} alt="No data found" />
                      <h2>No Task Assigned Yet</h2>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}





              <div className={style.mobileContainer}>
                {arr.map((ar, indexa) => {
                  return (
                    <div
                      key={indexa}
                      style={{
                        display: "block",
                        borderBottom: "1px solid #0000001A",
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <strong>{indexa + 1}. Database Creation</strong>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Assigned By :
                        </span>
                        <span style={{ fontSize: "16px", opacity: ".7" }}>
                          {" " + ar.b}
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Status :
                        </span>
                        <span
                          style={{
                            color: "#FCB902",
                            fontSize: "16px",
                            fontWeight: "300",
                          }}
                        >
                          {" " + ar.c}
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Priority :
                        </span>
                        <span style={{ fontSize: "16px", opacity: ".7" }}>
                          {" " + ar.d}
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Planned End Date :
                        </span>
                        <span style={{ fontSize: "16px", opacity: ".7" }}>
                          {" " + ar.a}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {totalTasksRecurring > limit && pageCountRecurring > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginRight: "1.3rem", marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCountRecurring}
                pageSize={limit}
                onPageChange={handlePageChangeRecurring}
                currentPage={currentPageRecurring}
              />
            </div>
          )}

          {/* <div className={style.responsivearrow}>
          <img src={downarrow} style={{ transform: "rotateZ(90deg)" }} />
          <img src={downarrow} style={{ transform: "rotateZ(-90deg)" }} />
          </div> */}

        </div>
          )}

        {showFilter && (
          <Filter
            closeFilter={closeFilter}
            handleApplied={handleApplied}
            userLists={userLists}
            filterData={allData}
            setassigned={setAssigned}
            setpriority={setPriority}
            setstatus={setStatus}
            data={data}
          />
        )}
      </div >
    </>
  );
};
export default MyTasks;
