import React, { useState, useEffect } from "react";
import style from "./EditTaskComponent.module.css";
import CustomDateInput from '../../createRecurringTask/component/CustomDateInput';
import calendar from "../../../../assets/calendar.png";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import Moment from "moment";
import Select from "react-select";
import cancel from "../../../../assets/cancell.png";
import { customStylesForTaskTracker, customStylesForTaskTracker1, customStylesForTaskTracker5, customStylesForTaskTracker6, customStylesForTaskTrackerSpaces } from "../../../Projects_and_Resource_Master/Clients/component/style";
import constants, { employeeId } from "../../../../Utils/constants";
import { notifySuccess } from "../../../atsDashboard/utils/notify";


const EditTaskComponent = (props) => {
  function getDaysInCurrentMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

    return new Date(year, month + 1, 0).getDate();
  }

  // Get the number of days in the current month
  const daysInCurrentMonth = getDaysInCurrentMonth();

  // Create an array with the days of the current month
  const daysOfMonth = Array.from({ length: daysInCurrentMonth }, (_, i) => i + 1);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    props.taskData.frequency === "Weekly" ? props.taskData.frequencyValue :
      props.taskData.frequency === "Monthly" ? props.taskData.frequencyValue :
        props.taskData.frequency === "Quarterly" ? props.taskData.frequencyValue :
          props.taskData.frequency === "Fortnightly" ? props.taskData.frequencyValue :
            props.taskData.frequency === "Daily" ? 'Daily' : null
  );
  const [selectedDay, setSelectedDay] = useState(
    props.taskData.frequencyValue &&
    props.taskData.frequencyValue.substring(0, 3)
  );
  const [creationDate, setCreationDate] = useState(props.taskData.creationDate);
  const [taskId, settaskId] = useState(props.taskData.taskId);
  const [taskTitle, settaskTitle] = useState(props.taskData.title);
  const [myTaskDetail, setMyTaskDetailByID] = useState();
  const [FunctionalAreaName, setFuncname] = useState("");
  const [taskDesc, settaskDesc] = useState(props.taskData.description);
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const [validate, setValidate] = useState(false);
  const [state, setState] = useState({
    description: "",
    title: "",
    frequency: props.taskData.frequency || "",
  });

  const [taskPEDate, settaskPEDate] = useState(
    Moment(props.taskData.planedEndDate)
  );
  const [taskAssignedTo, settaskAssignedTo] = useState(
    props.taskData.assignedTo ? props.taskData.assignedTo : employeeId
  );
  const [taskAssignedToName, settaskAssignedToName] = useState(
    props.taskData.name ? props.taskData.name : props.taskData.assignedToName
  );
  const [taskPriority, settaskPriority] = useState(props.taskData.priority);
  const [project, setPRoject] = useState(
    props.taskData.projectName
  );
  const [groupList, setGroupsList] = useState([]);
  const [functionalAreaa, setFunctionalAreaa] = useState(null);
  const [taskStatus, settaskStatus] = useState(props.taskData.status);
  const [showCal, setShowCal] = useState(false);
  const [arrayObj, setarrayObj] = useState([]);
  const [functionalArea, setFunctionalArea] = useState([]);
  const [peojectList, setProjectList] = useState([]);
  const [relatedTo, setRelataedTo] = useState();
  const [groupId, setGroupid] = useState(props.taskData.groupId)
  const [frequency, setFrequency] = useState();
  const [showErr, setShowErr] = useState(false);
  const [dateChange, setDateChange] = useState(false);
  const [RelatedToID, setRelatedToID] = useState();
  const [disable, setDisable] = useState(true);
  const [disablee, setDisablee] = useState();
  const [projectShow, setProjectShow] = useState(false);

  const [showDate, setShowDate] = useState(
    Moment(props.taskData.planedEndDate).format("D MMM YY")
  );
  employeeList.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const dayMapping = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday'
  };

  const getemployee = employeeList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getGroups = groupList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  function handleChange(id) {
    settaskAssignedTo(id);
  }
  function handleChange1(id) {
    setRelatedToID(id);
  }
  functionalArea?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );
  const FuncTionalAreaClear = () => {
    // setFunctionalOption(null)
    setFunctionalArea([]);
    setFunctionalAreaa(null);
    getFunctionalArea();
    setFuncname("");
  };

  useEffect(() => {
    if (groupId !== null) {
      setDisable(false)
    }
  })

  const CustomInputCross = ({groupId, setGroupid}) => {
    if (!groupId) return null; 
    return (
      <span style={{position: "absolute", height: "38px", width: "100%", zIndex: '1', display: "flex", alignItems: "center", paddingBottom: "2px" }}>
        <img
          src={cancel}
          alt="dropdownIcon"
          style={{ position: "absolute", zIndex: "1", marginLeft: "85%", cursor: "pointer"}}
          height="15"
          onClick={() => {setGroupid(null)}}
        />
      </span>
    );
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label ref={ref}>{props.value || props.placeholder}</label>
        <img
          type="image/svg+xml"
          width="20px"
          // style={ }}
          style={{ position: "absolute", right: "5%", top: "28%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const changeDate = (e) => {
    settaskPEDate(e);
    setShowDate(Moment(e).format("D MMM YY"));
    setShowCal(false);
    setDateChange(true);
  };

  const found = () => {
    // if()
    // return element.id === 2
    var e = document.getElementById("selectElement");
    var value = e.options[e.selectedIndex].value; // get selected option value
    var text = e.options[e.selectedIndex].text;
    settaskAssignedToName(text);
    settaskAssignedTo(value);
  };

  const functionalA = (e) => {
    if (e.target.value == 1) {
      setDisable(false);
      setProjectShow(true);
      getProject();
      setDisablee(e.target.value);
      // myTaskDetail?.functionalFieldArea=="Delivery"?project:null
    } else {
      setDisable(true);
      setPRoject(null);
      setProjectShow(false);

      setProjectList([]);
    }
    setFunctionalAreaa(e.target.value);
  };

  const url = constants.apiBaseUrl;
  const url2 = constants.apiBaseUrl2;
  const baseUrl = constants.apiBaseUrlResource;

  const updateData = () => {
    // console.log(!taskTitle, !state?.frequency, !selectedDate)
    if (!taskTitle || !state?.frequency || !selectedDate) {
      setValidate(true);
      return;
    }
    const data = {
      taskId: taskId,
      title: taskTitle,
      description: taskDesc,
      planedEndDate: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: taskAssignedTo,
      priority: "High",
      status: taskStatus,
      employeeRelatedToTask: RelatedToID,
      functionalFieldId: functionalAreaa,
      projectName: project,
      localDateTime: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
      groupId: groupId,
      frequency: state.frequency,
      frequencyValue: state.frequency === "Daily" ?
        Moment(taskPEDate).format("DD-MM-YYYY") :
        state.frequency === "Weekly" ?
          selectedDate :
          state.frequency === "Fortnightly" ?
            " 1&16 " :
            state.frequency === "Monthly" ?
              selectedDate :
              state.frequency === "Quarterly" ?
                selectedDate :
                null,
    };

    const oldData = {
      taskId: taskId,
      title: taskTitle,
      description: taskDesc,
      employeeRelatedToTask: RelatedToID,
      functionalFieldId: functionalAreaa,
      projectName: project,
      planedEndDate: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: taskAssignedTo,
      priority: taskPriority,
      status: taskStatus,
      localDateTime: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
      name: taskAssignedToName,
      created: creationDate,
      groupId: groupId
    };

    axios
      .post(url2 + "/editRecurringTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          notifySuccess("Updated Successfully")
          setShowErr(true);
          // history.goBack(-1)
          history.push({
            pathname: "/taskTracker/recurringTaskDetails",
            state: {
              task: oldData,
              activePage: 2,
            },
          });
        } else {
          notifySuccess("Updated Successfully")
          // history.goBack(-1)
          history.push({
            pathname: "/taskTracker/recurringTaskDetails",
            state: {
              task: oldData,
              activePage: 2,
            },
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };
  const statusOptions = ["Assigned", "Done", "Onhold"];
  const getFunctionalArea = () => {
    axios
      .get(url2 + "/getAllFunctionalArea", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setFunctionalArea(res.data.responseData);
      })
      .catch(() => { });
  };

  const getProject = () => {
    axios
      .get(baseUrl + "/getAllActiveProject", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setProjectList(res.data.responseData);
      })
      .catch(() => { });
  };

  // const getSingleDetailsById = (id) => {
  //   axios
  //     .get(url2 + `/getTaskDetailsByTaskId?taskId=${id}`, {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         Authorization: ` ${cookie.load("token")}`,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.responseData?.functionalFieldArea == "Delivery") {
  //         setDisable(false);
  //       } else {
  //         setDisable(true);
  //       }
  //       setMyTaskDetailByID(res.data.responseData);
  //       setFuncname(res.data.responseData?.functionalFieldArea);
  //       setPRoject(res.data.responseData?.projectName);
  //       setFunctionalAreaa(res.data.responseData?.functionalFieldAreaId);
  //       setRelatedToID(res.data.responseData?.taskRelatedToEmployeeId);
  //       setRelataedTo(res.data.responseData?.taskRelatedToEmployee);
  //       setGroupid(res.data.responseData?.groupId)
  //       setFrequency(res.data.responseData?.frequency)


  //     })
  //     .catch(() => { });
  // };
  useEffect(() => {
    async function getGroupData() {
      await axios
        .get(url2 + `/groupListing`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        })
        .then((res) => {
          if(Array.isArray(res.data.responseData)) setGroupsList(res.data.responseData);

        })
        .catch((err) => {
          return err;
        });
    }
    getGroupData();
  }, [taskAssignedTo]);
  const employeedata = () => {
    const historyData = axios
      .get(url + "/getEmployeeList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setEmployeeList(res.data.responseData.employeeList);
        setarrayObj(
          employeeList.map((item) => {
            return {
              label: item.name,
              id: item.id,
            };
          })
        );
      })
      .catch((err) => {
        return err;
      });
  };

  const history = useHistory();
  useEffect(() => {
    // getSingleDetailsById(props.taskData?.taskId);
    employeedata();
    getFunctionalArea();
    getProject();
  }, []);

  function onChange(e) {

    if (e.target.value === "Fortnightly") {
      setSelectedDate("1&16")
    } else if (e.target.value === "Weekly") {
      setSelectedDay(null)
      setSelectedDate(null)
    } else if (e.target.value === "Daily") {
      setSelectedDate("Daily")
    } else {
      setSelectedDate(null)
    }
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  }


  const handleDayClick = (day) => {
    setSelectedDay(day);
    const fullDayName = dayMapping[day];
    setSelectedDate(fullDayName);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className={style.rightSection}>
      <div className={style.container}>
        <span
          style={{ color: 'green', cursor: 'pointer', fontSize: '16px' }}
          onClick={() => history.push('/taskTracker/recurringtask')}
        >Recurring Tasks</span> <span> &gt; </span><span onClick={() =>
          history.push({
            pathname: "/taskTracker/recurringTaskDetails",
            state: {
              task: props.taskData,
              activePage: 1,
            },
          })
        } style={{ color: 'green', cursor: 'pointer', fontSize: '16px' }}>Task Details</span><span> &gt; </span><span style={{ fontSize: '16px' }}>Edit Task</span>

        <div className={style.createTask}>
          <div id="create_task_id" className={style.gridContainer}>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ width: '95.5%' }}>
                <label className={style.titleLabel}>
                  Task Title<span style={{ color: "red" }}>*</span><br></br>
                  <input
                    className={style.input}
                    // autocomplete="off"
                    name="task_title"
                    type="input"
                    id="tast_title"
                    value={taskTitle}
                    onChange={(e) => settaskTitle(e.target.value)}
                  />
                  <p
                    className={
                      validate && !taskTitle
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Enter Task Title
                  </p>
                </label>
              </div>

            </div>

            {/* <div className={style.descContainer}> */}
            <div>
              <label className={style.descLabel}>
                Task Description
                <textarea
                  name="task_description"
                  type="number"
                  id="task_description"
                  rows="7"
                  cols="78"
                  value={taskDesc}
                  onChange={(e) => settaskDesc(e.target.value)}
                  className={style.text}
                  style={{ marginTop: "0.7em" }}
                />
              </label>
            </div>
            {/* <div className={style.priorityLabel1} style={{ marginTop: "12px" }}>
              <label className={style.priorityLabel12}>
                Project <br />
                <select
                  className={style.input}
                  onChange={(e) => setPRoject(e.target.value)}
                  value={project}
                  disabled={disable}
                >
                  <option value="" disabled hidden selected>
                    ---select---
                  </option>
                  <option hidden>
                    {myTaskDetail?.functionalFieldArea == "Delivery" ||
                      (functionalAreaa == 1 && disablee == 1)
                      ? project
                      : null}
                  </option>
                  {peojectList?.map((val) => {
                    return <option value={val.name}>{val?.name}</option>;
                  })}
                </select>
              </label>
            </div> */}
            <div style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                <label className={style.assignLabel} style={{ marginTop: '6px' }}>
                  Assigned To<span style={{ color: "red" }}>*</span>
                  <Select
                    styles={customStylesForTaskTracker6}
                    classNamePrefix={"create_position"}
                    placeholder="Search By Client Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={getemployee?.find((i) => i.label === taskAssignedToName)}
                    onChange={(e) => {
                      settaskAssignedToName(e.label);
                      handleChange(e.value);
                    }}
                    options={getemployee}
                  />
                  <p
                    className={
                      validate && !taskAssignedTo
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Employee Name
                  </p>
                </label>
              </div>
              <div style={{ width: '50%', marginTop: '3px' }}>
                <label className={style.statusLabel}>
                  Space <br />
                  <div style={{position: "relative"}}>
                    {groupId && <CustomInputCross groupId={groupId} setGroupid={setGroupid} />}
                    <Select
                      styles={customStylesForTaskTrackerSpaces}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      maxMenuHeight={140}
                      onChange={(e) => {
                        setDisable(false)
                        setGroupid(e.value);
                      }}
                      value={groupId ? getGroups?.find(
                        (i) => i.value === groupId
                      ) : null }
                      options={getGroups}
                    />
                  </div>
                </label>
              </div>
            </div>

          </div>
          <div className={style.frequencyContainer}>
            <label className={style.font15}>Frequency <span style={{color: "red"}}>*</span></label>
            <div className={style.radioGroup}>
              <label className={style.radioLabel}>
                <input
                  type="radio"
                  name="frequency"
                  value="Daily"
                  checked={state.frequency === "Daily"}
                  onChange={onChange}
                />
                Daily
              </label>
              <label className={style.radioLabel}>
                <input
                  type="radio"
                  name="frequency"
                  value="Weekly"
                  checked={state.frequency === "Weekly"}
                  onChange={onChange}
                />
                Weekly
              </label>
              <label className={style.radioLabel}>
                <input
                  type="radio"
                  name="frequency"
                  value="Fortnightly"
                  checked={state.frequency === "Fortnightly"}
                  onChange={onChange}
                />
                Fortnightly
              </label>
              <label className={style.radioLabel}>
                <input
                  type="radio"
                  name="frequency"
                  value="Monthly"
                  checked={state.frequency === "Monthly"}
                  onChange={onChange}
                />
                Monthly
              </label>
              <label className={style.radioLabel}>
                <input
                  type="radio"
                  name="frequency"
                  value="Quarterly"
                  checked={state.frequency === "Quarterly"}
                  onChange={onChange}
                />
                Quarterly
              </label>
            </div>
            {state.frequency === "Weekly" && (
              <div className={style.daysOfWeekContainer}>
                {daysOfWeek.map((day) => (
                  <span
                    key={day}
                    style={{ fontSize: "13px" }}
                    className={`${style.weekday} ${selectedDay === day ? style.selectedDay : ""
                      }`}
                    onClick={() => handleDayClick(day)}
                  >
                    {day}
                  </span>
                ))}
              </div>
            )}
            {validate && state.frequency === "Weekly" && !selectedDay && (
              <span style={{ color: "red", fontSize: '15px' }}>Please select Day</span>
            )}
            {/* {state.frequency === "Monthly" && (
              <div className={style.datePickerContainer}>
                <label className={style.font15}>Select Date</label>
                <div style={{ display: "flex", position: 'relative', width: '200px', border: '1px solid', height: '33px' }}>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="MMMM d, yyyy"
                    className={style.datePicker}
                    customInput={
                      selectedDate ? (
                        <CustomInputCross
                          setSelectedDate={setSelectedDate}
                          selectedDate={selectedDate}
                          placeholder="Select Date"
                          icon={icon}
                        />
                      ) : (
                        <CustomInputClg icon={icon} />
                      )
                    }
                  />
                </div>
                {validate && (state.frequency === "Monthly") && !selectedDate && (
                  <span style={{ color: "red", fontSize: '15px' }}>Please select a date for {state.frequency} frequency</span>
                )}
              </div>
            )} */}
            {state.frequency === "Monthly" && (
              <div className={style.daysOfMonthContainer}>
                {daysOfMonth.map((date) => (
                  <span
                    key={date}
                    className={`${style.day} ${Number(selectedDate) === date ? style.selectedDay : ""}`}
                    onClick={() => handleDateClick(date)}
                  >
                    {date}
                  </span>
                ))}
              </div>
            )}
            {validate && state.frequency === "Monthly" && !selectedDate && (
              <span style={{ color: "red", fontSize: '15px' }}>Please select Date</span>
            )}
            {state.frequency === "Quarterly" && (
              <div className={style.daysOfMonthContainer}>
                {daysOfMonth.map((date) => (
                  <span
                    key={date}
                    className={`${style.day} ${Number(selectedDate) === date ? style.selectedDay : ""}`}
                    onClick={() => handleDateClick(date)}
                  >
                    {date}
                  </span>
                ))}
              </div>
            )}
            {validate && state.frequency === "Quarterly" && !selectedDate && (
              <span style={{ color: "red", fontSize: '15px' }}>Please select Date</span>
            )}
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "flex-end", gap: "10px"}}>
          <button className={style.cancelButton} onClick={()=>history.goBack()}>Cancel</button>
          <button className={style.submitButton} onClick={() => updateData()}>
            Update
          </button>
        </div>
      </div>
    </div >
  );
};

export default EditTaskComponent;
