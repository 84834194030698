import React, { useState, useEffect, useRef } from "react";
import style from "./TaskClosedByMeComponent.module.css";
import filterimg from "./../../../../assets/filter-results-button.png";
import Iconionicmdcheckbox from "../../../../assets/Iconionicmdcheckbox.png";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import milestone from "../../../../assets/milestone.png";
import Polygon from "../../../../assets/Polygon.png";
import downarrow from "../../../../assets/downarrow.png";
import loaderImg from "../../../../assets/loader-green2.gif";
import Pagination from "../../commonComponent/Pagination";
import Filter from "../../myTasks/component/Filter";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import axios from "axios";
import constants, { employeeId } from "../../../../Utils/constants";
import cookie from "react-cookies";
import { CgNotes } from "react-icons/cg";
import Modal from "react-responsive-modal";
const arr = [];

const TaskClosedByMeComponent = (props) => {
  const [tasks, setTasks] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [applied, setApplied] = useState(0);
  const [totalTasks, settotalTasks] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const history = useHistory();
  const limit = 15;
  const activePage = 2;
  const [sortBy, setSortBy] = useState("planedEndDate");
  const [sort, setSort] = useState("ASC");
  const [assigned, setAssigned] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [planned, setPlanned] = useState([]);
  const [planedEndDateOrder, setPlanedEndDateOrder] = useState(null);
  const [taskIdOrder, setTaskIdOrder] = useState(null);
  const [priority, setPriority] = useState([]);
  const [creationDateOrder, setCreationDateOrder] = useState(null);
  const [closingDateOrder, setClosingDateOrder] = useState(null);
  const [priorityOrder, setPriorityOrder] = useState(null);
  const [status, setStatus] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const prevApplied = useRef(applied);
  const [allData3, setAllData] = useState({
    assignedBy: assigned,
    assignedTo: assignedTo,
    creationDateOrder: creationDateOrder,
    planedEndDateOrder: planedEndDateOrder,
    priority: priority,
    priorityOrder: priorityOrder,
  });
  const [closedTaskList, setClosedTaskList] = useState([]);
  const [assignByList, setAssigneByList] = useState([]);
  const [assignToList, setAssigneToList] = useState([]);
  const [viewNote, setViewNote] = useState(false)
  const [closeNote, setCloseNote] = useState("")
  const [viewAddRevNote, setViewAddRevNote] = useState(false)
  const [doneRevNote, setdoneRevNote] = useState("")

  const baseUrl = constants.apiBaseUrl2;

  const getClosedTaskList = () => {
    setIsLoaded(true);
    axios
      .post(
        baseUrl +
        "/getClosedStatusData?limit=" +
        limit +
        "&page=" + currentPage +
        "&sortBy=" + sortBy + "&sort=" + sort,
        allData3,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: employeeId,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setClosedTaskList(res.data.responseData.tasks);
        settotalTasks(res.data.responseData.totalTask);
        setpageCount(
          res.data.responseData.totalNumberOfPagesAsPerGivenPageLimit
        );
      })
      .catch(() => { });
  };
  const getAssignedByData = () => {
    axios
      .get(baseUrl + "/getAssignedByData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setAssigneByList(res.data.responseData.employeeNames);
      })
      .catch(() => { });
  };

  const getAssignedToData = () => {
    axios
      .get(baseUrl + "/getAssignedToData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setAssigneToList(res.data.responseData.assignedTo);
      })
      .catch(() => { });
  };

  const handleApplied = (arg) => {
    setCurrentPage(1);
    if (
      allData3?.assignedTo.length !== 0 ||
      priority.length !== 0 ||
      status.length !== 0 ||
      allData3?.assignedBy.length !== 0
    ) {
      setApplied(prev=>prev+1);
    } else {
      setApplied(0);
    }
    setShowFilter(false);
    // getClosedTaskList();
  };

  const closeFilter = () => {
    setAllData((preValue) => {
      return preValue
        ? {
          ...preValue,
          assignedBy: [],
          assignedTo: [],
          priority: [],
          status: [],
        }
        : null;
    });
    setAssigned([]);
    setAssignedTo([]);
    setPriority([]);
    setShowFilter(false);
    setApplied(0);
  };

  useEffect(() => {
    getClosedTaskList();
    getAssignedByData();
    getAssignedToData();
  }, [currentPage, allData3, priority, sortBy, sort]);

  useEffect(()=>{
    if(!showFilter && prevApplied.current!==applied) getClosedTaskList();
    prevApplied.current = applied;
  },[currentPage, allData3, showFilter]);

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };


  const handleTaskDetail = (arg) => {
    history.push({
      pathname: "/TaskClosedByMeDetails",
      state: {
        task: arg,
        activePage: 2,
      },
    });
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleSort = (field) => {
    setSortBy(field);
    if (sort === "ASC") {
      setSort("DESC")
    } else {
      setSort("ASC")
    }
  };


  const renderSortArrow = (field) => {
    if (sortBy !== field) return null;

    return sort === "ASC" ? (
      <TiArrowSortedUp
        style={{
          // fontSize: "1.1rem",
          cursor: "pointer",
          color: "blue",
        }}
      />
    ) : (
      <TiArrowSortedDown
        style={{
          // fontSize: "1.1rem",
          cursor: "pointer",
          color: "blue",
        }}
      />
    );

  };

  return (
    <>
      <div className={style.taskTracker}>
        <div className={style.header}>
          <ViewNote viewNote={viewNote} setViewNote={setViewNote} note={closeNote} />
          <ViewDoneRevNote viewAddRevNote={viewAddRevNote} setViewAddRevNote={setViewAddRevNote} note={doneRevNote} />
          <div>
            <span>Closed Tasks</span>
            <p>
              <span>{tasks ? totalTasks : 0}</span>
            </p>
          </div>
          <button className={style.filter} onClick={handleFilter}>
            {applied && (
              <img className={style.appliedcheck} src={Iconionicmdcheckbox} />
            )}
            <img src={filterimg} />
            <span>Filter</span>
          </button>

          {showFilter && <img className={style.polygon} src={Polygon} />}
        </div>
        <div className={style.container}>
          {isLoaded ? (
            <div>
              <img src={loaderImg} alt="loader" className={style.loader} />
              <p className={style.loaderPara}>Loading...</p>
            </div>
          ) : (
            ""
          )}
          {closedTaskList?.length > 0 && !isLoaded ? (
            <div style={{ height: '485px', overflowY: 'auto' }}>
              <table >
                <thead style={{ position: 'sticky', top: '0' }}>
                  <tr style={{ cursor: 'pointer', textDecoration: 'underline' }} className={style.th}>
                    <th
                      onClick={() => handleSort("id")}
                      style={{ width: '4rem', paddingLeft:'.2rem'}}
                    >
                      Task {renderSortArrow("id")}<br></br>
                      ID
                    </th>
                    <th
                      onClick={() => handleSort("created")}
                      style={{ width: '5rem' }}
                    >
                      Creation
                      {renderSortArrow("created")}
                      <br></br> Date
                    </th>
                    <th
                      onClick={() => handleSort("closingDate")}
                      style={{ width: '5rem' }}
                    >
                      Closing{renderSortArrow("closingDate")}<br></br> Date
                    </th>
                    <th style={{width: "1rem"}}></th>
                    <th
                      onClick={() => handleSort("title")}
                      style={{ width: '26rem', paddingLeft: "10px" }}
                    >
                      Task Title
                      {renderSortArrow("title")}
                    </th>
                    <th
                      onClick={() => handleSort("assignedTo")}
                      style={{ width: '7rem' }}
                    >
                      Assigned To
                      {renderSortArrow("assignedTo")}
                    </th>
                    <th
                      onClick={() => handleSort("assignedBy")}
                      style={{ width: '7rem' }}
                    >
                      Assigned By
                      {renderSortArrow("assignedBy")}
                    </th>
                    <th
                      onClick={() => handleSort("priority")}
                      style={{ width: '5rem' }}
                    >
                      Priority
                      {renderSortArrow("priority")}
                    </th>
                    <th
                      onClick={() => handleSort("planedEndDate")}
                      style={{ width: '6rem' }}
                    >
                      Planned{renderSortArrow("planedEndDate")}<br></br> End Date

                    </th>
                  </tr>
                </thead>
                <tbody>
                  {closedTaskList &&
                    closedTaskList.map((task, index) => (
                      <tr
                        className={style.td}
                        key={index}>
                        <td
                          style={{ width: '4rem',paddingLeft:'.2rem'}}
                        >{task.taskId}</td>
                        <td
                          style={{ width: '5rem' }}
                        >
                          {Moment(task.created).format("DD MMM")}
                        </td>
                        <td
                          style={{ width: '5rem' }}
                        >
                          {task.closingDate ?
                            Moment(task.closingDate).format("DD MMM") : "-"}
                        </td>
                        <td style={{width: "1rem"}}>{closedTaskList[index]?.recurringTask ? (<span
                      style={{
                        fontSize: "10px",
                        color: "rgb(168, 50, 50)",
                        fontWeight: "800",
                        border: "1px solid rgb(168, 50, 50)",
                        borderRadius: "999px",
                        padding: "2px 6px",
                        // marginLeft: "5px",
                      }}
                    >
                      R
                    </span>) : null}</td>
                        <td
                          style={{paddingLeft: "10px", width: "26rem", fontSize: '15px', fontWeight: '500', textDecoration: 'underline', cursor: "pointer", color: task.status === "Closed" ? "red" : "#65A15E" }}
                          onClick={() => handleTaskDetail(task)}
                          title={task && task?.title?.length > 38 && task?.title}
                        >
                          <b>{task && task.title && task.title?.length > 38 ? task.title.substring(0, 38) + "..." : task.title}</b>
                        </td>
                        <td
                          style={{ width: '7rem' }}
                          title={task && task?.assignedToName?.length > 20 && task?.assignedToName}
                        >
                          {task && task.assignedToName && task.assignedToName?.length > 20 ? task.assignedToName.substring(0, 21) + "..." : task.assignedToName}
                        </td>
                        <td
                          style={{ width: '7rem' }}
                          title={task && task?.assignedByName?.length > 20 && task?.assignedByName}
                        >
                          {task && task.assignedByName && task.assignedByName?.length > 20 ? task.assignedByName.substring(0, 21) + "..." : task.assignedByName}
                        </td>
                        <td
                          style={{ width: '5rem' }}
                        >
                          {task.priority}
                        </td>
                        <td
                          style={{ width: '6rem' }}
                        >
                          {Moment(task.planedEndDate).format("DD MMM")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={style.noTasks}>
              {!isLoaded ? (
                <div style={{ display: "block" }}>
                  <img src={milestone} />
                  <h2>No Task Assigned Yet</h2>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          <div className={style.mobileContainer}>
            {arr.map((ar, indexar) => {
              return (
                <div
                  style={{
                    display: "block",
                    borderBottom: "1px solid #0000001A",
                    marginBottom: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <strong>{indexar + 1}. Database Creation</strong>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Assigned By :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.b}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Status :
                    </span>
                    <span
                      style={{
                        color: "#FCB902",
                        fontSize: "16px",
                        fontWeight: "300",
                      }}
                    >
                      {" " + ar.c}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Priority :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.d}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Planned End Date :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.a}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className={style.responsivearrow}>
          <img
            src={downarrow}
            style={{ transform: "rotateZ(90deg)" }}
            onClick={handlePageChange}
          />
          <img src={downarrow} style={{ transform: "rotateZ(-90deg)" }} />
        </div>
        {totalTasks > limit && pageCount > 0 && (
          <div
            className={style.paginationmobileContainer}
            style={{ marginRight: "1.3rem", marginTop: "20px" }}
          >
            <Pagination
              pagesCount={pageCount}
              pageSize={limit}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>

      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          assignTo={assignToList}
          filterData={allData3}
          setassigned={setAssigned}
          setassignedTo={setAssignedTo}
          setpriority={setPriority}
          data={assignByList}
        />
      )}
    </>
  );
};

const ViewNote = ({ viewNote, setViewNote, note }) => {
  return (<Modal
    open={viewNote}
    center
    onClose={() => setViewNote(false)}
    showCloseIcon={false}
    className={style.modal}
  >
    <div>{note}</div>
    <div className={style.btnROW}>
      <button className={style.ttAddBtn} onClick={() => setViewNote(false)}>Done</button>
    </div>
  </Modal>)
}

const ViewDoneRevNote = ({ viewAddRevNote, setViewAddRevNote, note }) => {
  return (<Modal
    open={viewAddRevNote}
    center
    onClose={() => setViewAddRevNote(false)}
    showCloseIcon={false}
    className={style.modal}
  >
    <div>{note}</div>
    <div className={style.btnROW}>
      <button className={style.ttAddBtn} onClick={() => setViewAddRevNote(false)}>Done</button>
    </div>
  </Modal>)
}

export default TaskClosedByMeComponent;
