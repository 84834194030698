import React, { useEffect, useState } from "react";
import style from "./ViewUser.module.css";
import SideBar from "../../sidebar/SideBar";
import axios from "axios";
import ConfirmPopup from "./ConfirmPopup";
import dropdownIcon from "../../../../assets/arrowdownIcon.png";
import "react-responsive-modal/styles.css";
import cookie from "react-cookies";
import calendarIcon from "../../../../assets/calendarIcon.png";
import { useHistory, useLocation } from "react-router-dom";
import Moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useQuery as useQuerys, useMutation, useQuery } from "react-query";
import cancel from "../../../../assets/cancell.png";
import moment from "moment";
import { ImageProfileView } from "../commonComponent/ImageProfileView";
import { FiArrowDown } from "react-icons/fi";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";
import {
  uploadResume,
  deleteResume,
  getBgvByCandidateEmail,
} from "../../../../Utils/axios/api";

import { CgNotes } from "react-icons/cg";
import ATSButton from "../../../atsDashboard/customTooltip/ATSButton";

import loaderImg from "../../../../assets/umsLoader.gif";

import {
  getAllUsersName,
  assignLeadToEmployee,
  markAsExit,
  markAsRetain,
  getConfirmationPeriod,
  addConfirmation,
  getClientList,
  assignClientToEmployee,
  getAllTeams,
  assignTeamToEmployee,
  createBGV,
  getBGVByEmployee,
  getReviseSalaryHistory,
} from "../../../../Utils/axios/umsApi";
import { CustomTooltipDiv } from "../../../atsDashboard/customTooltip/CustomTooltip";
import Modal from "react-responsive-modal";
import { FaLinkedin } from "react-icons/fa";
import { AiFillFolder } from "react-icons/ai";
import { employeeId } from "../../../../Utils/constants";
import { path } from "../../../../routes/PathNames";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import {
  umsAccessCode,
  renderTitle,
  renderValue,
} from "../../../../Utils/constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { PiImagesSquareBold } from "react-icons/pi";

const ViewUser = () => {
  const { checkValidateAccess } = useValidationAccess();
  const url = process.env.REACT_APP_UMS_APP_URL;
  const history = useHistory();
  const location = useLocation();
  let filterData = location?.state?.filterData;
  let currentPage = location?.state?.currentPage;
  let filter = location?.state?.filter;

  const { id, type } = location.state || { id: "none" };
  const { id: EmpId } = useParams();
  const [show, setShow] = useState(false);
  const [userDescription, setUserDescription] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [UnconfirmModal, setUnConfirmModal] = useState(false);
  const [resignedDate, setResignedDate] = useState(0);
  const [lwdDate, setLwdDate] = useState(null);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledResign, setDisabledResign] = useState(false);
  const [leadModal, setLeadModal] = useState(false);
  const [exitModal, setExitModal] = useState(false);
  const [formData, setFormData] = useState({ leadEmpId: "" });
  const { data: getAllUsers } = useQuerys(["getAllUsersName"], getAllUsersName);
  const [validate, setValidate] = useState(false);
  const [candidateDropped, setCondidateDropped] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [salaryNotes, setSalaryNotes] = useState("");
  const [showSalaryNotes, setShowSalaryNotes] = useState(false);
  const [markAsExitData, setMarkAsExit] = useState({
    empId: id,
    exitDecisionDate: "",
    exitPlanDate: "",
    changeByEmpId: employeeId,
  });
  const AllUsersList = getAllUsers?.map((e) => {
    return { value: e.id, label: e.label };
  });
  const [retainModal, setRetainModal] = useState(false);
  const [retain, setRetain] = useState({
    empId: id,
    retain: "",
    retainDate: "",
  });

  const [errorRetain, setErrorRetain] = useState(false);
  const [showImageProfile, setShowImageProfile] = useState(false);
  const [addCanfirmation, setAddCanfirmation] = useState(false);
  const [confirmationData, setConfirmationData] = useState({
    actualConfirmationDate: null,
    confirmationDate: null,
    delayReason: null,
    empId: id,
    periodId: null,
  });
  const [errorCanfirmation, setErrorCanfirmation] = useState(false);
  const [assignClientShow, setAssignClientShow] = useState(false);
  const [assignTeamShow, setAssignTeamShow] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [teamId, setTeamId] = useState(false);

  const [bgvRequestModels, setBgvRequestModels] = useState([]);
  const [editStatus, setEditStatus] = useState(true);
  const [editRequest, setEditRequest] = useState([]);
  const [uploadIn, setUploadIn] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [comments, setComment] = useState(null);
  const [showCommnent, setShowComment] = useState(false);
  const [validation, setValidation] = useState(false);
  const [showSalaryHistory, setShowSalaryHistory] = useState(false);

  const [showSalaryNotesModal, setShowSalaryNotesModal] = useState(false);
  const [salaryNote, setSalaryNote] = useState(null)
  // const [currentPage, setCurrentPage] = useState(1);
  // const [pageCount, setpageCount] = useState(0);



  const [loading, setIsLoading] = useState(false);
  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf style={{ fontSize: "2rem" }} />;
      case "docx":
        return <AiOutlineFileWord style={{ fontSize: "2rem" }} />;
      default:
        return;
    }
  };
  useEffect(() => {
    setMarkAsExit((prev) => ({
      ...prev,
      changeByEmpId: employeeId,
      exitDecisionDate: userDescription?.exitDecisionDate,
      exitPlanDate: userDescription?.exitPlanDate,
      exitComments: userDescription?.exitComment,
    }));
    setClientId(userDescription?.clientId);
    setTeamId(userDescription?.teamId);
  }, [userDescription]);
  const handleBack = () => {
    if (type === "bgv") {
      return history.push(path.ums_bgvReport);
    } else {
      return history.push(path.ums_userList, {
        id,
        filterData,
        currentPage,
        filter,
      });
    }
  };
  const handleRedirect = () => {
    setUnConfirmModal(false);
    setConfirmModal(false);
    setTimeout(() => {
      history.push(path.ums_userDescription, {
        id,
        filterData,
        currentPage,
        filter,
      });
    }, 2000);
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label ref={ref} style={{ padding: "8px", fontWeight: 600 }}>
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "13%", zIndex: "0" }}
          src={calendarIcon}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const edituser = () => {
    history.push(path.ums_editUser, { id, filterData, currentPage, filter });
  };
  const uploadResumeMutation = useMutation(uploadResume, {
    onSuccess: (res) => {
      setUploadUrl(res);
    },
    onError: () => { },
  });

  function addCommas(number) {
    var x;
    x = number.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  }

  function handleConfirmPopupClose() {
    setConfirmModal(false);
    setResignedDate(null);
    setError(false);
  }
  const changeStatusPopup = () => {
    setConfirmModal(true);
  };
  const changeStatusPopup1 = () => {
    setUnConfirmModal(true);
  };

  const changeOnCancel = () => {
    setUnConfirmModal(false);
    setResignedDate(null);
    setError(false);
  };

  const MarkasChange = (data) => {
    setDisabled(true);
    axios
      .put(url + `/updateUserStatus`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setShow(!show);
        setDisabled(false);
        if (res.data.responseStatus.statusCode === 1) {
          notify(res.data.responseData.message);
        } else {
          notifySuccess(res.data.responseData);
          handleRedirect();
          setError(false);
          setLwdDate(null);
          setCondidateDropped(false);
        }
      })
      .catch(() => { });
  };

  const getApplicationDetails = (id) => {
    setIsLoading(true);
    if (id === "none") {
      id = EmpId;
    }
    axios
      .get(url + `/getUserById?useId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setUserDescription(res.data.responseData);
      })
      .catch(() => { }).finally(() => {
        setIsLoading(false);
      });
  };

  const changeResignedStatus = (id, status) => {
    if (!resignedDate) {
      setError(true);
      return false;
    }
    setConfirmModal(false);
    const data = {
      date: Moment(resignedDate).format(),
      employeeId: employeeId,
      resignStatus: status === "resigne" ? true : false,
      userId: id,
    };
    setDisabledResign(true);
    axios
      .put(url + "/updateUserResignStatus", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: employeeId,
        },
      })
      .then((res) => {
        setResignedDate(null);
        setError(false);
        setDisabledResign(false);
        setShow(!show);
        if (res.data.responseStatus.statusCode === 1) {
          notify(res.data.responseData.message);
        } else {
          notifySuccess(res.data.responseData);
          handleRedirect();
        }
      })
      .catch(() => { });
  };
  useEffect(() => {
    getApplicationDetails(id);
  }, [id, show]);

  const customStyles = {
    control: () => ({
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "transparent 0% 0% no-repeat padding-box",
      backgroundColor: "#f5f6fa",
      minWidth: "inherit",
      margin: "8px 1.5% 0px 1.7%",
      height: "38px",
      outline: "none",
    }),
    placeholder: () => ({
      display: "none",
    }),
  };
  const notifySuccess = (success) =>
    toast.success(success, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const notify = (error) =>
    toast.error(error, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          alt="dropdownIcon"
          style={{
            position: "absolute",
            top: "32%",
            right: "6%",
            width: "18px",
          }}
        />
      </span>
    );
  };
  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{ padding: "8px", fontWeight: 600, cursor: "pointer" }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          alt="cancel"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "30%",
            cursor: "pointer",
          }}
          onClick={() =>
            setMarkAsExit((prev) => ({ ...prev, [props?.properties]: "" }))
          }
        />
      </div>
    );
  });
  const mutation = useMutation(assignLeadToEmployee, {
    onSuccess: (res) => {
      LeadSuccess(res.responseData);
      setLeadModal(false);
      getApplicationDetails(id);
      setValidate(false);
      setFormData({ ...formData, leadEmpId: null });
    },
    onError: () => {
      notify1("Something went wrong..!");
      setValidate(false);
    },
  });
  const mutateMark = useMutation(markAsRetain, {
    onSuccess: (res) => {
      LeadSuccess("Mark as retain successfully");
      setRetainModal(false);
      setRetain((prev) => ({
        ...prev,
        retain: "",
        retainDate: null,
      }));
      setErrorRetain(false);
      getApplicationDetails(id);
    },
    onError: (res) => {
      notify1("Something went wrong..!");
      setRetainModal(false);
    },
  });
  const assignClientMutation = useMutation(assignClientToEmployee, {
    onSuccess: (res) => {
      setAssignClientShow(false);
      getApplicationDetails(id);
      LeadSuccess(res);
    },
    onError: (res) => {
      notify1("Something went wrong..!");
      setAssignClientShow(false);
    },
  });
  const assignTeamMutation = useMutation(assignTeamToEmployee, {
    onSuccess: (res) => {
      setAssignTeamShow(false);
      getApplicationDetails(id);
      LeadSuccess(res);
    },
    onError: () => {
      notify1("Something went wrong..!");
      setAssignTeamShow(false);
    },
  });
  const AssignLeadMethod = () => {
    if (!formData?.leadEmpId) {
      setValidate(true);
    } else {
      mutation.mutate({ empId: id, leadEmpId: formData?.leadEmpId });
    }
  };
  const LeadSuccess = (res) =>
    toast.success(res, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const notify1 = (error) =>
    toast.error(error, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const handleLastDay = (id) => {
    if (!lwdDate) {
      setError(true);
    } else {
      const data = {
        employeeId: employeeId,
        lastWorkingDate: Moment(lwdDate).format(),
        userId: id,
      };
      MarkasChange(data);
      setCondidateDropped(false);
    }
  };
  const exitMutation = useMutation(markAsExit, {
    onSuccess: () => {
      setExitModal(false);
      notifySuccess("Mark as Exit Planned Successfully");
      getApplicationDetails(id);
      handleRedirect();
      setError(false);
      setMarkAsExit((prevData) => ({
        ...prevData,
        exitPlanDate: "",
        exitDecisionDate: "",
      }));
    },
    onError: () => {
      notify("Something Went Wrong");
    },
  });
  const handleMarkAsExit = () => {
    if (userDescription?.exitDecisionDate || userDescription?.exitPlanDate) {
      return exitMutation.mutate(markAsExitData);
    }
    if (markAsExitData?.exitDecisionDate) {
      exitMutation.mutate(markAsExitData);
    } else {
      setError(true);
    }
  };
  // const deleteMutation = useMutation(deleteEmployee, {
  //   onSuccess: (res) => {
  //     notifySuccess(res);
  //     setTimeout(() => {
  //       history.push(path.ums_userList);
  //     }, 3000);
  //   },
  //   onError: (res) => {
  //     notify("Something Went Wrong");
  //   },
  // });
  const retainOption = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];
  const handleReatain = () => {
    if (retain?.retain === false || retain?.retain === true) {
      if (retain?.retainDate) {
        mutateMark.mutate(retain);
      } else {
        setErrorRetain(true);
      }
    } else {
      setErrorRetain(true);
    }
  };
  const errorMess = () => {
    if (retain?.retain === false || retain?.retain === true) {
      return false;
    } else {
      return true;
    }
  };
  const handleUploadImage = (value) => {
    const exe = value?.split("@")[1];

    if (
      exe?.split(".")[1]?.toLowerCase() === "png" ||
      exe?.split(".")[1]?.toLowerCase() === "jpg" ||
      exe?.split(".")[1]?.toLowerCase() === "jpeg"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const { data: cafirmation } = useQuerys(
    ["getConfirmationPeriod"],
    getConfirmationPeriod,
    { refetchOnWindowFocus: false }
  );
  const canfirmationOption = cafirmation?.map((e) => {
    return {
      value: e?.id,
      label: e?.name,
    };
  });
  const { data: getClientData } = useQuerys(["getClientList"], getClientList, {
    refetchOnWindowFocus: false,
  });
  const {
    data: reviseSalaryHistory,
    isError: isErrorSalaryHistory,
    isLoading: isLoadingSalaryHistory,
    refetch,
  } = useQuerys(
    ["getReviseSalaryHistory"],
    () => getReviseSalaryHistory(200, id, 1),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    showSalaryHistory && refetch();
  }, [showSalaryHistory, refetch]);

  const { data: getTeamsData } = useQuerys(["getAllTeams"], getAllTeams, {
    refetchOnWindowFocus: false,
  });
  const teamOption = getTeamsData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });
  const clientOption = getClientData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });
  const disabledConfirmationDate = (value, cmdate) => {
    if (value === 1) {
      return true;
    } else if (value > 1 && cmdate === "confimationDate") {
      return true;
    } else {
      return false;
    }
  };
  const handleConfimation = (e) => {
    if (e?.value === 1) {
      setConfirmationData((prev) => ({
        ...prev,
        periodId: e?.value,
        confirmationDate: null,
        actualConfirmationDate: null,
        delayReason: null,
      }));
    } else {
      let date = moment(userDescription?.dateOfJoining);
      let newDate = date?.add(e?.label?.split(" ")[0], "months");
      setConfirmationData((prev) => ({
        ...prev,
        periodId: e?.value,
        confirmationDate: newDate,
      }));
    }
  };
  const mutationConfirmation = useMutation(addConfirmation, {
    onSuccess: (res) => {
      LeadSuccess(res);
      setErrorCanfirmation(false);
      setAddCanfirmation(false);
      setConfirmationData((prev) => ({
        ...prev,
        actualConfirmationDate: null,
        confirmationDate: null,
        delayReason: null,
        periodId: null,
      }));
      getApplicationDetails(id);
    },
  });
  const handleConfirmationDate = () => {
    if (confirmationData?.periodId) {
      mutationConfirmation.mutate(confirmationData);
    } else {
      setErrorCanfirmation(true);
    }
  };
  const handleTeam = () => {
    if (teamId) {
      assignTeamMutation?.mutate({
        teamId: teamId,
        empId: id,
      });
      setError(false);
    } else {
      setError(true);
    }
  };
  const handleUpload = () => {
    let key = [...bgvRequestModels];
    for (let i = 0; i < key.length; i++) {
      if (i === Number(uploadIn)) {
        key[uploadIn]["bgvDocUrl"] = uploadUrl;
      }
    }

    setUploadIn(null);
    setBgvRequestModels([...key]);
  };
  useEffect(() => {
    uploadUrl && handleUpload();
  }, [uploadUrl]);
  const createBgvMutation = useMutation(createBGV, {
    onSuccess: () => {
      LeadSuccess("BGV Submited Successfully");
      setBgvRequestModels([]);
      setEditRequest({});
      setEditStatus(true);
      refetchEmp();
    },
  });
  const editBgvMutation = useMutation(createBGV, {
    onSuccess: () => {
      LeadSuccess("BGV Updated Successfully");
      setBgvRequestModels([]);
      setEditRequest({});
      setEditStatus(true);
      refetchEmp();
    },
  });
  const handleBgv = () => {
    if (bgvRequestModels[0].companyName === "") {
      setValidation(true);
      return;
    }
    let data = bgvRequestModels?.filter((item) => item?.companyName !== "");
    data = data?.map((e) => {
      return {
        bgvstatus: e?.bgvstatus,
        comments: e?.comments,
        companyName: e?.companyName,
        employeeId: e?.employeeId,
        feedbackStatus: e?.feedbackStatus,
        bgvDocUrl: e?.bgvDocUrl,
      };
    });
    createBgvMutation.mutate({ bgvRequestModels: data });
  };
  const handleEditBgv = () => {
    if (editRequest?.companyName) {
      editBgvMutation.mutate({ bgvRequestModels: [editRequest] });
    } else {
      setValidation(true);
    }
  };
  const {
    data: bgvByEmp,
    isLoading,
    isError,
    refetch: refetchEmp,
  } = useQuery(
    [
      "getBGVByEmployee",
      userDescription,
      {
        refetchOnWindowFocus: false,
      },
    ],
    () => userDescription?.id && getBGVByEmployee(userDescription?.id)
  );
  const bgvByEmailMutation = useMutation(getBgvByCandidateEmail, {
    onSuccess: (res) => {
      if (res?.length > 0) {
        let newData = res?.map((e) => {
          return {
            bgvstatus: e?.bgvStatus,
            comments: e?.comments,
            companyName: e?.companyName,
            feedbackStatus: e?.feedbackStatus,
            employeeId: userDescription?.id,
            bgvDocUrl: e?.bgvDocUrl,
          };
        });
        setBgvRequestModels(newData);
      } else {
        notify1("BGV Doesn't Exists");
      }
    },
  });
  const EditResumeMutation = useMutation(uploadResume, {
    onSuccess: (res) => {
      setEditRequest((prev) => ({
        ...prev,
        bgvDocUrl: res,
      }));
    },
    onError: () => { },
  });

  return (
    <>
      <ToastContainer />
      {/*  <Header />*/}
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.breadscrumb} style={{ padding: "6px" }}></div>
          <p className={style.vieUser}>View User</p>
          {(loading ? (<div>
            <img
              src={loaderImg}
              alt="loader"
              style={{ marginLeft: "46%", marginTop: "8%", width: "12%" }}
            />
            <p
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                paddingLeft: "38%",
                position: "absolute",
                color: "#289e98",
                marginTop: "-4px",
                fontStyle: "italic",
                marginLeft: "33.5%",
              }}
            >
              Loading...
            </p>
          </div>) : <>
            <div className={style.createposition}>
              <div className={style.inner_card}>
                <div
                  className={style.rowContainernew}
                  style={{ marginTop: "15px" }}
                >
                  <div className={style.cp_rowcol123}>
                    <p className={style.cp_label}>Type</p>
                    <p className={style.cp_value}>{userDescription?.type}</p>
                  </div>
                  <div className={style.cp_rowcol123}>
                    <p className={style.cp_label}>Code</p>
                    <p className={style.cp_value}>{userDescription?.code}</p>
                  </div>

                  <div className={style.cp_rowcol123}>
                    <p className={style.cp_label}>Name</p>
                    <p
                      className={style.cp_value}
                      title={renderTitle(userDescription?.name, 20)}
                    >
                      {renderValue(userDescription?.name, 20)}
                    </p>
                  </div>
                  <div className={style.cp_rowcol123}>
                    <p className={style.cp_label}>Date of Joining</p>
                    <p className={style.cp_value}>
                      {userDescription?.dateOfJoining && Moment(userDescription?.dateOfJoining).format("DD MMM YY")}
                    </p>
                  </div>
                  {handleUploadImage(userDescription?.uploadImageUrl) && (
                    <div className={style.cp_rowcol123} style={{ width: "8%" }}>
                      <img
                        src={userDescription?.uploadImageUrl}
                        alt="Userprofileimage"
                        style={{
                          borderRadius: "50%",
                          width: "3.5rem",
                          height: "3.5rem",
                          border: "1px solid black",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowImageProfile(true)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{ height: "", overflowY: "", overflowX: "hidden" }}
            >
              <div className={style.createposition}>
                <div className={style.inner_card}>
                  <div
                    className={style.rowContainernew}
                    style={{ marginTop: "15px" }}
                  >
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Email</p>
                      <p
                        className={style.cp_value}
                        title={renderTitle(userDescription?.email, 35)}
                      >
                        {renderValue(userDescription?.email, 35)}
                      </p>
                    </div>
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Personal Email</p>
                      <p
                        className={style.cp_value}
                        title={renderTitle(userDescription?.personalEmail, 35)}
                      >
                        {renderValue(userDescription?.personalEmail, 35)}
                      </p>
                    </div>
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Mobile Number</p>
                      <p className={style.cp_value}>{userDescription?.number}</p>
                    </div>
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>WhatsApp Mobile Number</p>
                      <p className={style.cp_value}>
                        {userDescription?.whatsappNumber}
                      </p>
                    </div>

                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Career Start Date</p>
                      <p className={style.cp_value}>
                        {Moment(userDescription?.careerStartDate).format(
                          "DD MMM YY"
                        )}
                      </p>
                    </div>
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Total Experience</p>
                      <p className={style.cp_value}>
                        {~~(userDescription?.totalExperience / 12)
                          ? ~~(userDescription?.totalExperience / 12)
                          : 0}{" "}
                        Years{" "}
                        {userDescription?.totalExperience % 12
                          ? userDescription?.totalExperience % 12
                          : 0}{" "}
                        Months
                      </p>
                    </div>
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Relevant Experience </p>
                      <p className={style.cp_value}>
                        {~~(userDescription?.relevantExperience / 12)
                          ? ~~(userDescription?.relevantExperience / 12)
                          : 0}{" "}
                        Years{" "}
                        {userDescription?.relevantExperience % 12
                          ? userDescription?.relevantExperience % 12
                          : 0}{" "}
                        Months
                      </p>
                    </div>

                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Technology </p>
                      <p className={style.cp_value}>
                        {userDescription?.technology}
                      </p>
                    </div>

                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Additional Skill</p>
                      <p className={style.cp_value}>
                        {userDescription?.additionalSkill
                          ?.map((e) => e.additionalSkillName)
                          .join(", ")}
                      </p>
                    </div>
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Role</p>
                      <p className={style.cp_value}>
                        {userDescription?.employeeDesignation}
                      </p>
                    </div>
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Salary Range</p>
                      <p className={style.cp_value}>
                        {userDescription?.salaryRangeName}
                      </p>
                    </div>
                    {userDescription?.period && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label}>Confirmation</p>
                        <p className={style.cp_value}>
                          {userDescription?.period}
                        </p>
                      </div>
                    )}
                    {userDescription?.confirmationDate && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label} >
                          Confirmation Date
                        </p>
                        <p className={style.cp_value}>
                          {moment(userDescription?.confirmationDate).format(
                            "DD MMM YY"
                          )}
                        </p>
                      </div>
                    )}
                    {userDescription?.actualConfirmationDate && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label} style={{ color: "green" }}>
                          {" "}
                          Actual Confirmation Date
                        </p>
                        <p className={style.cp_value} style={{ color: "green" }}>
                          {moment(userDescription?.actualConfirmationDate).format(
                            "DD MMM YY"
                          )}
                        </p>
                      </div>
                    )}
                    {userDescription?.delayReason && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label}> Confirmation Remarks</p>
                        <p className={style.cp_value}>
                          {userDescription?.delayReason}
                        </p>
                      </div>
                    )}

                    {userDescription?.functionalAreaName && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label}>Function Area</p>
                        <p className={style.cp_value}>
                          {userDescription?.functionalAreaName}
                        </p>
                      </div>
                    )}

                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Bond</p>
                      <p className={style.cp_value}>
                        {userDescription?.bond ? "Yes" : "No"}
                      </p>
                    </div>
                    {userDescription?.bond && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label}> Bond End Date</p>
                        <p className={style.cp_value}>
                          {userDescription?.bondDate
                            ? moment(userDescription?.bondDate).format(
                              "DD MMM YY"
                            )
                            : null}
                        </p>
                      </div>
                    )}

                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}> PF Applicable</p>
                      <p className={style.cp_value}>
                        {userDescription?.pfApplicable ? "Yes" : "No"}
                      </p>
                    </div>

                    {userDescription?.assignedLead && (
                      <div className={style.cp_rowcol122}>
                        <>
                          <p className={style.cp_label}>Assigned Lead</p>
                          <p className={style.cp_value}>
                            {userDescription?.assignedLead}
                          </p>
                        </>
                      </div>
                    )}
                    {userDescription?.teamName && (
                      <div className={style.cp_rowcol122}>
                        <>
                          <p className={style.cp_label}>Assigned Team</p>
                          <p className={style.cp_value}>
                            {userDescription?.teamName}
                          </p>
                        </>
                      </div>
                    )}
                    {userDescription?.clientName && (
                      <div className={style.cp_rowcol122}>
                        <>
                          <p className={style.cp_label}>Assigned Client</p>
                          <p className={style.cp_value}>
                            {userDescription?.clientName}
                          </p>
                        </>
                      </div>
                    )}

                    {userDescription?.resignStatus && (
                      <div className={style.cp_rowcol122}>
                        <>
                          <p className={style.cp_label}>Resignation Date</p>
                          <p className={style.cp_value}>
                            {Moment(userDescription?.resignationDate).format(
                              "DD MMM YY"
                            )}
                          </p>
                        </>
                      </div>
                    )}
                    {userDescription?.retain === true && (
                      <div className={style.cp_rowcol122}>
                        <>
                          <p className={style.cp_label}>Retain End Date</p>
                          <p className={style.cp_value}>
                            {Moment(userDescription?.retainDate).format(
                              "DD MMM YY"
                            )}
                          </p>
                        </>
                      </div>
                    )}

                    {userDescription?.lastWorkingDayDate && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label} style={{ color: "red" }}>Last Working Day</p>
                        <p className={style.cp_value} style={{ color: "red" }}>
                          {Moment(userDescription?.lastWorkingDayDate).format(
                            "DD MMM YY"
                          )}
                        </p>
                      </div>
                    )}
                    {userDescription?.exitDecisionDate && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label} style={{ color: "red" }}>
                          Exit Decision Date
                        </p>
                        <p className={style.cp_value} style={{ color: "red" }}>
                          {Moment(userDescription?.exitDecisionDate).format(
                            "DD MMM YY"
                          )}
                        </p>
                      </div>
                    )}
                    {userDescription?.exitPlanDate && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label}>Exit Planned Date</p>
                        <p className={style.cp_value}>
                          {Moment(userDescription?.exitPlanDate).format(
                            "DD MMM YY"
                          )}
                        </p>
                      </div>
                    )}
                    {userDescription?.exitComment && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label}>Exit Note</p>
                        <p className={style.cp_value}>
                          {userDescription?.exitComment}
                        </p>
                      </div>
                    )}
                    {userDescription?.dob && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label}>Date of Birth</p>
                        <p className={style.cp_value}>
                          {Moment(userDescription?.dob).format("DD MMM YYYY")}
                        </p>
                      </div>
                    )}
                    {userDescription?.marriageAnniversary && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label}>Marriage Anniversary</p>
                        <p className={style.cp_value}>
                          {Moment(userDescription?.marriageAnniversary).format(
                            "DD MMM YY"
                          )}
                        </p>
                      </div>
                    )}
                    {userDescription?.linkedInUrl && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label}>LinkedIn URL</p>
                        <a
                          href={userDescription?.linkedInUrl}
                          target="_black"
                          rel="noreferrer"
                          title="Click here"
                          className={style.cp_value}
                        >
                          <FaLinkedin size={25} color="blue" />
                        </a>
                      </div>
                    )}

                    {userDescription?.folderUrl && (
                      <div className={style.cp_rowcol122}>
                        <p className={style.cp_label}>Folder URL</p>
                        <a
                          href={userDescription?.folderUrl}
                          target="_black"
                          rel="noreferrer"
                          title="Click here"
                          className={style.cp_value}
                        >
                          <AiFillFolder size={25} color="blue" />
                        </a>
                      </div>
                    )}

                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>BGV Applicable</p>
                      <p className={style.cp_value}>
                        {userDescription?.isBGVeligible ? "Yes" : "No"}
                      </p>
                    </div>

                    {/* <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Salary ( As on Date)</p>
                    <p
                      className={style.cp_value}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {" "}
                      <MdCurrencyRupee />
                      {userDescription?.salary}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>
                      Salary (As per Project & Costing )
                    </p>
                    <p
                      className={style.cp_value}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <MdCurrencyRupee />
                      {userDescription?.reviseSalaryAsProjectCosting}
                    </p>
                  </div> */}
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Marital Status</p>
                      <p className={style.cp_value}>
                        {userDescription?.maritalStatus}
                      </p>
                    </div>
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Gender</p>
                      <p className={style.cp_value}>{userDescription?.gender}</p>
                    </div>
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Location</p>
                      <p className={style.cp_value_Notes}>
                        {userDescription?.location}
                      </p>
                    </div>
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Emergency Contact Name</p>
                      <p className={style.cp_value_Notes}>
                        {userDescription?.emergencyContactName}
                      </p>
                    </div>
                    <div className={style.cp_rowcol122} style={{ marginBottom: "5px" }}>
                      <p className={style.cp_label}>Emergency Contact Number</p>
                      <p className={style.cp_value_Notes}>
                        {userDescription?.emergencyContactNumber}
                      </p>
                    </div>
                    <div className={style.cp_rowcol122} style={{ marginBottom: "5px" }}>
                      {userDescription?.eventImageUrl && (
                        <div className={style.cp_rowcol122}>
                          <p className={style.cp_label} style={{ width: "20rem" }}>Additional Image</p>
                          <a
                            href={userDescription?.eventImageUrl}
                            target="_black"
                            rel="noreferrer"
                            title="Click here"
                            className={style.cp_value}
                          >
                            <PiImagesSquareBold size={25} color="blue" />
                          </a>
                        </div>
                      )}
                    </div>
                    <div className={style.cp_rowcol122}>
                      <p className={style.cp_label}>Notes</p>
                      <p className={style.cp_value_Notes}>
                        {userDescription?.note}
                      </p>
                    </div>
                  </div>

                </div>

              </div>
              {/*Experience*/}
              <div
                className={style.createposition}
                style={{ marginBottom: "2rem" }}
              >
                <p className={style.title}>Work Experience</p>
                <div
                  className={style.Cu_row1}
                  style={{ paddingBottom: "0.5rem" }}
                >
                  <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                    <p className={style.cp_label}>Company Name</p>
                  </div>
                  <div className={style.cu_col2_to} style={{ flex: "0 0 17%" }}>
                    {
                      <div className={style.multiinput}>
                        <div
                          className={style.cp_label}
                          style={{ flex: "0 0 55%" }}
                        >
                          From
                        </div>
                        <div
                          className={style.cp_label}
                          style={{ flex: "0 0 55%" }}
                        >
                          To
                        </div>
                      </div>
                    }
                  </div>
                  <div className={style.cu_col3} style={{ flex: "0 0 9%" }}>
                    <p className={style.cp_label}>Dur</p>
                  </div>
                  <div className={style.cu_col3} style={{ flex: "0 0 19%" }}>
                    <p className={style.cp_label}>Role</p>
                  </div>

                  <div className={style.cu_col3} style={{ flex: "0 0 27%" }}>
                    <p className={style.cp_label}>Reason For Change</p>
                  </div>
                </div>
                {userDescription?.workExpResponseModels?.map((item, index) => {
                  return (
                    <div>
                      <div className={style.Cu_row1} key={item?.id}>
                        <div
                          className={style.cu_col1}
                          style={{ flex: "0 0 23%" }}
                        >
                          <p className={style.cu_field2}>{item?.companyName}</p>
                        </div>
                        <div
                          className={style.cu_col2}
                          style={{ flex: "0 0 17%" }}
                        >
                          <div className={style.multiinput}>
                            <div
                              className={style.cu_field2}
                              style={{ flex: "0 0 55%" }}
                            >
                              {/* <p className={style.cu_field2}> */}
                                {item?.startDate &&
                                  moment(item?.startDate).format("MMM YYYY")}
                              {/* </p> */}
                            </div>

                            <div
                              className={style.cu_field2}
                              style={{ flex: "0 0 55%" }}
                            >
                              {/* <p className={style.cu_field2}> */}
                                {item?.endDate &&
                                  moment(item?.endDate).format("MMM YYYY")}
                                {item?.presentlyWorking && "Till Now"}
                              {/* </p> */}
                            </div>
                          </div>
                        </div>
                        <div className={style.cu_col3} style={{ flex: "0 0 9%" }}>
                          <p className={style.cu_field2}>
                            {(function () {
                              let year1 = Math.floor(item.expDurationYear);
                              let month = item.expDurationMonth;
                              let year = year1 > 0 ? year1 + " Y  " : "";
                              month =
                                year1 > 0 && month > 0
                                  ? ` - ${month}  M `
                                  : month > 0
                                    ? `${month}  M `
                                    : "";
                              return `   ${year}  ${month}`;
                            })()}
                          </p>
                        </div>
                        <div
                          className={style.cu_col3}
                          style={{ flex: "0 0 19%" }}
                        >
                          <p className={style.cu_field2}>{item?.role}</p>
                        </div>
                        <div
                          className={style.cu_col1Reasone}
                          style={{ flex: "0 0 27%" }}
                        >
                          <p className={style.cu_fieldReasone}>
                            {item?.reasonForChange}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/*Academics */}
              <div className={style.createposition}>
                <p className={style.title}>Academics</p>
                <div
                  className={style.Cu_row1}
                  style={{ paddingBottom: "0.5rem" }}
                >
                  <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                    <p className={style.cp_label}>Degree</p>
                  </div>
                  <div className={style.cu_col2} style={{ flex: "0 0 20%" }}>
                    <p className={style.cp_label}>Passing Year</p>
                  </div>
                  <div className={style.cu_col2} style={{ flex: "0 0 15%" }}>
                    <p className={style.cp_label}>Grade</p>
                  </div>
                  <div className={style.cu_col3} style={{ flex: "0 0 35%" }}>
                    <p className={style.cp_label}>
                      Board of Education/University
                    </p>
                  </div>
                </div>
                {userDescription?.educationResponseModels?.map((item, index) => {
                  return (
                    <div key={item?.id}>
                      <div
                        className={style.Cu_row1}
                        style={{ paddingBottom: "1rem" }}
                      >
                        <div
                          className={style.cu_col1}
                          style={{ flex: "0 0 23%" }}
                        >
                          <p className={style.cp_value}>{item?.degree}</p>
                        </div>
                        <div
                          className={style.cu_col2}
                          style={{ flex: "0 0 20%" }}
                        >
                          <p className={style.cp_value}>{item?.passingYear}</p>
                        </div>
                        <div
                          className={style.cu_col2}
                          style={{ flex: "0 0 15%" }}
                        >
                          <p className={style.cp_value}>
                            {item?.grade}
                            {item?.gradeStatus === "CGPA" && " CGPA"}
                            {item?.gradeStatus === "Percentage" && "%"}
                          </p>
                        </div>
                        <div
                          className={style.cu_col3}
                          style={{ flex: "0 0 35%" }}
                        >
                          <p className={style.cp_value}>{item?.board}</p>
                        </div>
                      </div>
                      {/* Second */}
                    </div>
                  );
                })}
              </div>

              {/*BGV */}
              <div className={style.createposition}>
                <p className={style.title}>BGV</p>
                {editStatus ? (
                  <>
                    <div
                      className={style.bgvcantainer}
                      style={{ marginBottom: "0px", padding: "0 0.6rem" }}
                    >
                      <div className={style.bgvrow1}>
                        <div>
                          <p className={style.label}>Company Name</p>
                        </div>
                        <div>
                          <p className={style.label} style={{ width: "11rem" }}>
                            Status
                          </p>
                        </div>
                        <div>
                          <p className={style.label}> &nbsp;Positive/Negative</p>
                        </div>
                        <div></div>
                        <div>
                          <p className={style.label}> &nbsp;Upload Doc</p>
                        </div>
                        <div>
                          <p className={style.label}>Comments</p>
                        </div>
                      </div>
                    </div>
                    {bgvRequestModels?.map((item, key) => {
                      return (
                        <div className={style.bgvcantainer}>
                          {bgvRequestModels?.length > 0 && (
                            <div
                              className={style.cross}
                              onClick={() => {
                                setValidation(false)
                                let arr = [...bgvRequestModels];
                                arr?.splice(key, 1);
                                setBgvRequestModels([...arr]);
                              }}
                            >
                              ❌
                            </div>
                          )}
                          <div className={style.bgvrow1}>
                            <div>
                              <input
                                className={style.bgvInput}
                                value={bgvRequestModels[key].companyName}
                                onChange={(e) => {
                                  setValidation(false)
                                  let arr = [...bgvRequestModels];
                                  arr[key].companyName = e?.target?.value;
                                  setBgvRequestModels([...arr]);
                                }}
                              />
                            </div>

                            <div
                              className={style.radiocantainer}
                              style={{ width: "11rem" }}
                            >
                              <p className={style.radioitem}>
                                <input
                                  type="radio"
                                  id={`test1${key}`}
                                  name={`radio-group${key}`}
                                  checked={
                                    bgvRequestModels[key]?.bgvstatus === "Done"
                                  }
                                  onChange={() => {
                                    let arr = [...bgvRequestModels];
                                    arr[key].bgvstatus = "Done";
                                    setBgvRequestModels([...arr]);
                                  }}
                                />
                                <label for={`test1${key}`}>Done</label>
                              </p>
                              <p className={style.radioitem}>
                                <input
                                  type="radio"
                                  id={`test2${key}`}
                                  name={`radio-group${key}`}
                                  checked={
                                    bgvRequestModels[key]?.bgvstatus === "N.A"
                                  }
                                  onChange={() => {
                                    let arr = [...bgvRequestModels];
                                    arr[key].bgvstatus = "N.A";
                                    setBgvRequestModels([...arr]);
                                  }}
                                />
                                <label for={`test2${key}`}>N.A</label>
                              </p>
                              <p className={style.radioitem}>
                                <input
                                  type="radio"
                                  id={`test3${key}`}
                                  name={`radio-group${key}`}
                                  checked={
                                    bgvRequestModels[key]?.bgvstatus === "N.R"
                                  }
                                  onChange={() => {
                                    let arr = [...bgvRequestModels];
                                    arr[key].bgvstatus = "N.R";
                                    setBgvRequestModels([...arr]);
                                  }}
                                />
                                <label for={`test3${key}`}>N.R</label>
                              </p>
                            </div>

                            <div className={style.radiocantainer}>
                              <p className={style.radioitem}>
                                <input
                                  type="radio"
                                  id={`test4${key}`}
                                  name={`positive${key}`}
                                  checked={
                                    bgvRequestModels[key]?.feedbackStatus === true
                                  }
                                  disabled={
                                    bgvRequestModels[key]?.bgvstatus === "N.A" ||
                                    bgvRequestModels[key]?.bgvstatus === "N.R"
                                  }
                                  onChange={() => {
                                    let arr = [...bgvRequestModels];
                                    arr[key].feedbackStatus = true;
                                    setBgvRequestModels([...arr]);
                                  }}
                                />
                                <label for={`test4${key}`}>Positive</label>
                              </p>
                              <p className={style.radioitem}>
                                <input
                                  type="radio"
                                  id={`test5${key}`}
                                  name={`positive${key}`}
                                  checked={
                                    bgvRequestModels[key]?.feedbackStatus ===
                                    false
                                  }
                                  disabled={
                                    bgvRequestModels[key]?.bgvstatus === "N.A" ||
                                    bgvRequestModels[key]?.bgvstatus === "N.R"
                                  }
                                  onChange={() => {
                                    let arr = [...bgvRequestModels];
                                    arr[key].feedbackStatus = false;
                                    setBgvRequestModels([...arr]);
                                  }}
                                />
                                <label for={`test5${key}`}>Negative</label>
                              </p>
                            </div>
                            <div
                              onClick={() => {
                                let arr = [...bgvRequestModels];
                                arr[key].feedbackStatus = null;
                                arr[key].bgvstatus = null;
                                setBgvRequestModels([...arr]);
                              }}
                            >
                              <GrPowerReset />
                            </div>

                            {bgvRequestModels[key]?.bgvDocUrl ? (
                              <div>
                                <p className={style.rmpdf}>
                                  {checkfileType(
                                    bgvRequestModels[key]?.bgvDocUrl
                                      ?.split("@")[1]
                                      ?.split(".")[1]
                                  )}
                                </p>
                                <p
                                  className={style.rmpdfcross1}
                                  onClick={() => {
                                    deleteResume(
                                      bgvRequestModels[key]?.bgvDocUrl?.resumeUrl
                                    );
                                    let arr = [...bgvRequestModels];
                                    arr[key].bgvDocUrl = null;
                                    setBgvRequestModels([...arr]);
                                  }}
                                >
                                  x
                                </p>
                              </div>
                            ) : (
                              <div>
                                <label
                                  htmlFor={`myFileInput${key}`}
                                  className={style.fileInputLabel}
                                >
                                  <input
                                    type="file"
                                    id={`myFileInput${key}`}
                                    // name="myFile"
                                    className={style.fileInput}
                                    accept=".pdf,.doc,.docx"
                                    onChange={(e) => {
                                      setUploadIn(key);
                                      uploadResumeMutation.mutate(
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                </label>
                              </div>
                            )}
                            <div>
                              <textarea
                                className={style.bgvInput}
                                value={bgvRequestModels[key].comments}
                                onChange={(e) => {
                                  let arr = [...bgvRequestModels];
                                  arr[key].comments = e?.target?.value;
                                  setBgvRequestModels([...arr]);
                                }}
                              />
                            </div>
                          </div>
                          <p
                            className={
                              validation
                                ? style.errorText
                                : style.errorTextHidden
                            }
                          >
                            Please Enter Company Name
                          </p>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div
                      className={style.bgvcantainer}
                      style={{ marginBottom: "0px" }}
                    >
                      <div className={style.bgvrow1}>
                        <div>
                          <p className={style.label}>Company Name</p>
                        </div>
                        <div>
                          <p className={style.label} style={{ width: "11rem" }}>
                            &nbsp;Status
                          </p>
                        </div>
                        <div>
                          <p className={style.label}> &nbsp;Positive/Negative</p>
                        </div>
                        <div></div>
                        <div>
                          <p className={style.label}>Upload Doc</p>
                        </div>
                        <div>
                          <p className={style.label}>Comments</p>
                        </div>
                      </div>
                    </div>
                    <div className={style.bgvcantainer}>
                      <div className={style.bgvrow1}>
                        <div>
                          <input
                            className={style.bgvInput}
                            value={editRequest.companyName}
                            onChange={(e) => {
                              setEditRequest((prev) => ({
                                ...prev,
                                companyName: e?.target.value,
                              }));
                            }}
                          />
                          <p
                            className={
                              validation
                                ? style.errorText
                                : style.errorTextHidden
                            }
                          >
                            Please Enter Company Name
                          </p>
                        </div>

                        <div
                          className={style.radiocantainer}
                          style={{ width: "11rem" }}
                        >
                          <p className={style.radioitem}>
                            <input
                              type="radio"
                              id={`test1`}
                              name={`radio-group`}
                              onChange={(e) => {
                                setEditRequest((prev) => ({
                                  ...prev,
                                  bgvstatus: "Done",
                                }));
                              }}
                              checked={editRequest.bgvstatus === "Done"}
                            />
                            <label for={`test1`}>Done</label>
                          </p>
                          <p className={style.radioitem}>
                            <input
                              type="radio"
                              id={`test2`}
                              name={`radio-group`}
                              onChange={(e) => {
                                setEditRequest((prev) => ({
                                  ...prev,
                                  bgvstatus: "N.A",
                                }));
                              }}
                              checked={editRequest.bgvstatus === "N.A"}
                            />
                            <label for={`test2`}>N.A</label>
                          </p>
                          <p className={style.radioitem}>
                            <input
                              type="radio"
                              id={`test3`}
                              name={`radio-group`}
                              onChange={(e) => {
                                setEditRequest((prev) => ({
                                  ...prev,
                                  bgvstatus: "N.R",
                                }));
                              }}
                              checked={editRequest.bgvstatus === "N.R"}
                            />
                            <label for={`test3`}>N.R</label>
                          </p>
                        </div>

                        <div className={style.radiocantainer}>
                          <p className={style.radioitem}>
                            <input
                              type="radio"
                              id={`test4`}
                              name={`positive`}
                              disabled={
                                editRequest?.bgvstatus === "N.A" ||
                                editRequest?.bgvstatus === "N.R"
                              }
                              onChange={(e) => {
                                setEditRequest((prev) => ({
                                  ...prev,
                                  feedbackStatus: true,
                                }));
                              }}
                              checked={editRequest.feedbackStatus === true}
                            />
                            <label for={`test4`}>Positive</label>
                          </p>
                          <p className={style.radioitem}>
                            <input
                              type="radio"
                              id={`test5`}
                              name={`positive`}
                              disabled={
                                editRequest?.bgvstatus === "N.A" ||
                                editRequest?.bgvstatus === "N.R"
                              }
                              onChange={() => {
                                setEditRequest((prev) => ({
                                  ...prev,
                                  feedbackStatus: false,
                                }));
                              }}
                              checked={editRequest.feedbackStatus === false}
                            />
                            <label for={`test5`}>Negative</label>
                          </p>
                        </div>
                        <div
                          onClick={() => {
                            setEditRequest((prev) => ({
                              ...prev,
                              feedbackStatus: null,
                              bgvstatus: null,
                            }));
                          }}
                        >
                          <GrPowerReset />
                        </div>

                        {editRequest.bgvDocUrl ? (
                          <div>
                            <p className={style.rmpdf}>
                              {checkfileType(
                                editRequest.bgvDocUrl
                                  ?.split("@")[1]
                                  ?.split(".")[1]
                              )}
                            </p>
                            <p
                              className={style.rmpdfcross}
                              onClick={() => {
                                deleteResume(editRequest.bgvDocUrl);
                                setEditRequest((prev) => ({
                                  ...prev,
                                  bgvDocUrl: null,
                                }));
                              }}
                            >
                              x
                            </p>
                          </div>
                        ) : (
                          <div>
                            <label
                              htmlFor={`myFileInput`}
                              className={style.fileInputLabel}
                            >
                              <input
                                type="file"
                                id={`myFileInput`}
                                // name="myFile"
                                className={style.fileInput}
                                accept=".pdf,.doc,.docx"
                                onChange={(e) => {
                                  // setUploadIn(key);
                                  EditResumeMutation.mutate(e.target.files[0]);
                                }}
                              />
                            </label>
                          </div>
                        )}
                        <div>
                          <textarea
                            className={style.bgvInput}
                            value={editRequest.comments}
                            onChange={(e) => {
                              setEditRequest((prev) => ({
                                ...prev,
                                comments: e?.target?.value,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {!editStatus ? (
                  <div className={style.bgvbutton}>
                    <button
                      onClick={() => {
                        setValidation(false)
                        setEditRequest({});
                        setEditStatus(true);
                      }}
                      styles={{ marginRight: "1rem" }}
                    >
                      Cancel
                    </button>
                    <button onClick={() => handleEditBgv()}>Submit</button>
                  </div>
                ) : (<div className={style.bgvbutton}>
                  <button
                    onClick={() =>
                      setBgvRequestModels((prev) => [
                        ...prev,
                        {
                          bgvStatus: "",
                          employeeId: userDescription?.id,
                          comments: "",
                          companyName: "",
                          feedbackStatus: "",
                          bgvDocUrl: null,
                        },
                      ])
                    }
                  >
                    + ADD BGV
                  </button>
                  <button
                    onClick={() =>
                      bgvByEmailMutation.mutate(userDescription?.personalEmail)
                    }
                  >
                    Auto Fill BGV
                  </button>
                </div>)}
                <hr />
                <>
                  <section className={style.th}>
                    <div>Company Name</div>
                    <div>Status</div>
                    <div>Positive/Negative</div>
                    <div>Upload Doc</div>
                    <div>Comments</div>
                    <div></div>
                    <div></div>
                  </section>
                  {isLoading && <div className={style.no_data}>Loader...</div>}
                  {isError && (
                    <div className={style.no_data}>Something Went Wrong</div>
                  )}
                  {!isLoading && !isError ? (
                    bgvByEmp?.length > 0 ? (
                      bgvByEmp?.map((item, key) => {
                        return (
                          <section
                            className={style.td}
                            key={key}
                            style={{ position: "relative" }}
                          >
                            <CustomTooltipDiv
                              index={`c${key}`}
                              value={item?.companyName}
                              limit={25}
                              tooltipstyle={{ left: "5%" }}
                            />

                            <div>{item?.bgvstatus}</div>
                            <div>
                              {item?.hasOwnProperty("feedbackStatus") && (
                                <span
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: item?.feedbackStatus ? "green" : "red",
                                  }}
                                >
                                  {item?.feedbackStatus ? "Positive" : "Negative"}
                                </span>
                              )}
                            </div>
                            <div>
                              <a
                                href={item?.bgvDocUrl}
                                title="Click Here"
                                target="_black"
                                download="example.pdf"
                                className={style.download}
                                style={{ fontSize: "1.3rem", color: "black" }}
                              >
                                {checkfileType(
                                  item?.bgvDocUrl?.split("@")[1]?.split(".")[1]
                                )}
                              </a>
                            </div>
                            {item?.comments?.length > 70 ? (
                              <div
                                onClick={() => {
                                  setShowComment(true);
                                  setComment(item?.comments);
                                }}
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                {item?.comments}
                              </div>
                            ) : (
                              <div>{item?.comments}</div>
                            )}
                            <div
                              onClick={() => {
                                setEditStatus(false);
                                setEditRequest(item);
                                setBgvRequestModels([]);
                              }}
                            >
                              <MdModeEditOutline
                                style={{ color: "green", fontSize: "1.3rem" }}
                              />
                            </div>
                            <div
                              onClick={() => {
                                setShow(true);
                              }}
                            >
                              {/*<MdDelete
                          style={{ color: "red", fontSize: "1.3rem" }}
                      />*/}
                            </div>
                          </section>
                        );
                      })
                    ) : (
                      <div className={style.no_data}>No Data</div>
                    )
                  ) : null}
                </>
              </div>

              {bgvRequestModels?.length > 0 && (
                <div
                  className={style.bgvbutton}
                  style={{ marginRight: "0.3rem" }}
                >
                  <button onClick={() => handleBgv()}>Submit</button>
                </div>
              )}

              {/*Salary History  Table*/}
              {/* {showSalaryHistory && (
              <div
                className={style.createposition}
                style={{ marginBottom: "2rem" }}
              >
                <div className={style.salaryth}>
                  <p className={style.label}>Salary (As On Date)</p>
                  <p className={style.label}>Salary As Project Costing</p>
                  <p className={style.label}>Updated Date</p>
                </div>
                {isErrorSalaryHistory && (
                  <div className={style.no_data}>Something Went Wrong</div>
                )}
                {isLoadingSalaryHistory && (
                  <div className={style.no_data}>Loader...</div>
                )}
                {!isErrorSalaryHistory && !isLoadingSalaryHistory ? (
                  reviseSalaryHistory?.length > 0 ? (
                    reviseSalaryHistory?.map((item) => {
                      return (
                        <div
                          className={style.salarytd}
                          key={item?.salaryAsOnDate + item?.updatedDate}
                        >
                          <p className={style.label}>{item?.salaryAsOnDate}</p>
                          <p className={style.label}>
                            {item?.salaryAsProjectCosting && (
                              <>{item?.salaryAsProjectCosting}</>
                            )}
                          </p>
                          <p className={style.label}>
                            {Moment(item?.updatedDate).format("DD MMM YY")}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <div className={style.no_data}>No Data</div>
                  )
                ) : null}
              </div>
            )} */}

              {/* Salary (As On Date) */}
              <Modal
                open={showSalaryNotes}
                center
                onClose={() => setShowSalaryNotes(false)}
                showCloseIcon={false}
                classNames={{
                  modal: "reactmodal",
                }}
              >
                <div className={style.modal_container1}>
                  <div>
                    <p className={style.textM}>{salaryNotes}</p>
                  </div>
                  <div className={style.M_button}>
                    <button
                      onClick={() => {
                        setShowSalaryNotes(false);
                        setSalaryNotes(null);
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </Modal>
              <Modal
                open={showCommnent}
                center
                onClose={() => setShowComment(false)}
                showCloseIcon={false}
                classNames={{
                  modal: "reactmodal",
                }}
              >
                <div className={style.modal_container1}>
                  <div>
                    <p className={style.textM}>{comments}</p>
                  </div>
                  <div className={style.M_button}>
                    <button
                      onClick={() => {
                        setShowComment(false);
                        setComment(null);
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </Modal>

              <ImageProfileView
                show={showImageProfile}
                image={userDescription?.uploadImageUrl}
                okaybtn={() => setShowImageProfile(false)}
              ></ImageProfileView>
              <ConfirmPopup open={candidateDropped}>
                <div className={style.confirmPopup}>
                  <h5 className={style.confirmPopupTitle1}>
                    Last Working Day <span style={{ color: "red" }}>*</span>
                  </h5>
                  <div className={style.csd} style={{ marginLeft: "43px" }}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      // minDate={new Date()}
                      selected={lwdDate}
                      startDate={null}
                      onChange={(date) => setLwdDate(date)}
                      defaultValue={null}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={<CustomInputClg />}
                      style={{ backgroundColor: "#F5F6FA" }}
                    />
                  </div>
                  <p
                    className={
                      error && !lwdDate ? style.errorText : style.errorTextHidden
                    }
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Please
                    Select Last Working Day
                  </p>

                  <div className={style.btnCont}>
                    <button
                      className={style.delete_btn}
                      // onClick={() =>
                      //   changeResignedStatus(userDescription?.id, "resigne")
                      // }
                      onClick={() => handleLastDay(userDescription?.id)}
                    >
                      In-Active
                    </button>
                    <button
                      className={style.delete_btn}
                      onClick={() => {
                        setCondidateDropped(false);
                        setLwdDate(null);
                        setError(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ConfirmPopup>
              <div
                className={style.createposition}
                style={{ paddingBottom: "15px" }}
              >
                <p className={style.title}>Salary Breakup</p>
                <div
                  className={style.rowContainernew}
                  style={{ marginTop: "15px" }}
                >
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Effective Date</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.salaryComponentsEffectiveFrom
                        ? moment(userDescription?.salaryComponentsEffectiveFrom).format(
                          "DD MMM YY"
                        )
                        : null}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Basic</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.basic >= 0 ? addCommas(Number(userDescription?.basic)) : (<p className={style.cp_value_salary_details} style={{ visibility: "hidden" }}>9</p>)}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>HRA</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.hra >= 0 && addCommas(Number(userDescription?.hra))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>OA</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.oa >= 0 && addCommas(Number(userDescription?.oa))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Conveyance</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.conveyance >= 0 && addCommas(Number(userDescription?.conveyance))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Bonus</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.bonus >= 0 ? addCommas(Number(userDescription?.bonus)) : (<p className={style.cp_value_salary_details} style={{ visibility: "hidden" }}>9</p>)}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Gross Salary</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.grossSalary >= 0 && addCommas(Number(userDescription?.grossSalary))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Employer PF</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.employerPF >= 0 && addCommas(Number(userDescription?.employerPF))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Employee PF</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.employeePF >= 0 && addCommas(Number(userDescription?.employeePF))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Gratuity</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.gratuity >= 0 ? addCommas(Number(userDescription?.gratuity)) : (<p className={style.cp_value_salary_details} style={{ visibility: "hidden" }}>9</p>)}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>SA1</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.sa1 >= 0 && addCommas(Number(userDescription?.sa1))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>SA2</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.sa2 >= 0 && addCommas(Number(userDescription?.sa2))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Incentives</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.incentives >= 0 && addCommas(Number(userDescription?.incentives))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Variable Bonus</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.variableBonus >= 0 ? addCommas(Number(userDescription?.variableBonus)) : (<p className={style.cp_value_salary_details} style={{ visibility: "hidden" }}>9</p>)}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Joining Bonus</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.joiningBonus >= 0 && addCommas(Number(userDescription?.joiningBonus))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Retention Bonus</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.retentionBonus >= 0 && addCommas(Number(userDescription?.retentionBonus))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Others</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.others >= 0 && addCommas(Number(userDescription?.others))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Current Monthly Core CTC</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.salary >= 0 ? addCommas(Number(userDescription?.salary)) : (<p className={style.cp_value_salary_details} style={{ visibility: "hidden" }}>9</p>)}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Current Yearly Core CTC</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.currentYearlyCoreCTC >= 0 && addCommas(Number(userDescription?.currentYearlyCoreCTC))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Current Monthly Full CTC</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.currentMonthlyFullCTC >= 0 && addCommas(Number(userDescription?.currentMonthlyFullCTC))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Current Yearly Full CTC</p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.currentYearlyFullCTC >= 0 && addCommas(Number(userDescription?.currentYearlyFullCTC))}
                    </p>
                  </div>
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>
                      Salary ( As per Project & Costing )
                    </p>
                    <p className={style.cp_value_salary_details}>
                      {userDescription?.reviseSalaryAsProjectCosting >= 0 ? addCommas(
                        Number(userDescription?.reviseSalaryAsProjectCosting)
                      ) : (<p className={style.cp_value_salary_details} style={{ visibility: "hidden" }}>9</p>)}
                    </p>
                  </div>
                  <div className={style.Cu_rowcol122}>
                    <p className={style.cp_label}>Salary Note</p>
                    <p className={style.cp_value_Notes}>
                      {userDescription?.salaryNotes?.split("\n").map(item => {
                        return <>{item}<br /></>
                      })}
                    </p>
                  </div>
                </div>
              </div>

              {showSalaryHistory && <div style={{ padding: "0px 28px" }}>
                <p className={style.salaryHistoryHeading}>Salary History</p>
                <div className="table-responsive" style={{ maxHeight: "200px" }}>
                  <table className={`table ${style.salaryTable}`}>
                    <thead className={style.salaryTableHeader} style={{ zIndex: !showSalaryNotesModal ? 999 : "" }}>
                      <tr>
                        <th scope="col">Updated Date</th>
                        <th scope="col">Updated By</th>
                        <th scope="col">Effective Date</th>
                        <th scope="col">Basic</th>
                        <th scope="col">HRA</th>
                        <th scope="col">OA</th>
                        <th scope="col">Conveyance</th>
                        <th scope="col">Bonus</th>
                        <th scope="col">Gross Salary</th>
                        <th scope="col">Employer PF</th>
                        <th scope="col">Employee PF</th>
                        <th scope="col">Gratuity</th>
                        <th scope="col">SA1</th>
                        <th scope="col">SA2</th>
                        <th scope="col">Incentives</th>
                        <th scope="col">Variable Bonus</th>
                        <th scope="col">Joining Bonus</th>
                        <th scope="col">Retention Bonus</th>
                        <th scope="col">Others</th>
                        <th scope="col">Current Monthly Core CTC</th>
                        <th scope="col">Current Yearly Core CTC</th>
                        <th scope="col">Current Monthly Full CTC</th>
                        <th scope="col">Current Yearly Full CTC</th>
                        <th>Salary ( As per Project & Costing )</th>
                        <th>Salary Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        reviseSalaryHistory && reviseSalaryHistory?.length > 0 ? reviseSalaryHistory?.map((item, i) => (
                          <tr>
                            <td>{moment(item?.updatedOn).format("DD MMM YY")}</td>
                            <td>{item?.changedByEmpName}</td>
                            <td>{item?.salaryComponentsEffectiveFrom && moment(item?.salaryComponentsEffectiveFrom).format("DD MMM YY")}</td>
                            <td>{item?.basic && addCommas(Number(item?.basic))}</td>
                            <td>{item?.hra && addCommas(Number(item?.hra))}</td>
                            <td>{item?.oa && addCommas(Number(item?.oa))}</td>
                            <td>{item?.conveyance && addCommas(Number(item?.conveyance))}</td>
                            <td>{item?.bonus && addCommas(Number(item?.bonus))}</td>
                            <td>{item?.grossSalary && addCommas(Number(item?.grossSalary))}</td>
                            <td>{item?.employerPF && addCommas(Number(item?.employerPF))}</td>
                            <td>{item?.employeePF && addCommas(Number(item?.employeePF))}</td>
                            <td>{item?.gratuity && addCommas(Number(item?.gratuity))}</td>
                            <td>{item?.sa1 && addCommas(Number(item?.sa1))}</td>
                            <td>{item?.sa2 && addCommas(Number(item?.sa2))}</td>
                            <td>{item?.incentives && addCommas(Number(item?.incentives))}</td>
                            <td>{item?.variableBonus && addCommas(Number(item?.variableBonus))}</td>
                            <td>{item?.joiningBonus && addCommas(Number(item?.joiningBonus))}</td>
                            <td>{item?.retentionBonus && addCommas(Number(item?.retentionBonus))}</td>
                            <td>{item?.others && addCommas(Number(item?.others))}</td>
                            <td>{item?.currentMonthlyCoreCTC && addCommas(Number(item?.currentMonthlyCoreCTC))}</td>
                            <td>{item?.currentYearlyCoreCTC && addCommas(Number(item?.currentYearlyCoreCTC))}</td>
                            <td>{item?.currentMonthlyFullCTC && addCommas(Number(item?.currentMonthlyFullCTC))}</td>
                            <td>{item?.currentYearlyFullCTC && addCommas(Number(item?.currentYearlyFullCTC))}</td>
                            <td>{item?.salaryAsProjectAndCosting && addCommas(Number(item?.salaryAsProjectAndCosting))}</td>
                            <td>{item?.salaryNote ? <CgNotes title="View Note" style={{ cursor: "pointer" }} onClick={() => { setShowSalaryNotesModal(true); setSalaryNote(item?.salaryNote) }} /> : ""}</td>
                          </tr>
                        )) : <tr>
                          <td colSpan="15" style={{ textAlign: "center", color: "#289e98", fontSize: "1rem" }}>
                            No Data Found
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              }








              <div className={style.container} style={{ marginBottom: "2rem" }}>
                <button className={style.back_btn} onClick={() => handleBack()}>
                  Back
                </button>
                <div>
                  {checkValidateAccess(umsAccessCode?.edit_user) && (
                    <button
                      className={style.edit_btn}
                      onClick={edituser}
                      style={{ marginRight: "1rem" }}
                    >
                      Edit
                    </button>
                  )}
                  <button
                    className={style.edit_btn}
                    onClick={() => {
                      setShowbtn((prev) => !prev);
                    }}
                  >
                    More
                    <FiArrowDown
                      style={{ fontSize: "1.5rem", paddingLeft: "0.2rem" }}
                    />
                  </button>
                </div>
              </div>
              {showbtn && (
                <>
                  <div
                    className={style.container1}
                    style={{ marginBottom: "1rem" }}
                  >
                    <button
                      className={style.Salary}
                      onClick={() => {
                        setShowSalaryHistory((prev) => !prev);
                      }}
                    >
                      Salary History
                    </button>
                    {/* {checkValidateAccess(umsAccessCode?.delete_user) && (
                    <button
                      className={style.back_btn}
                      onClick={() =>
                        deleteMutation.mutate({
                          employeeId: id,
                        })
                      }
                    >
                      Delete
                    </button>
                  )} */}
                    {checkValidateAccess(umsAccessCode?.mark_as_exit_planned) && (
                      <button
                        className={style.Exit_btn}
                        onClick={() => setExitModal(true)}
                      >
                        Mark as Exit Planned
                      </button>
                    )}
                    {checkValidateAccess(umsAccessCode?.assign_lead) && (
                      <button
                        className={style.inActive_btn}
                        onClick={() => setLeadModal(true)}
                        style={{ width: "7rem" }}
                      >
                        Assign Lead
                      </button>
                    )}
                    {userDescription?.active ? (
                      <>
                        {checkValidateAccess(
                          umsAccessCode?.mark_as_in_active
                        ) && (
                            <button
                              className={style.inActive_btn}
                              disabled={disabled}
                              // onClick={() => MarkasChange(EmployeeId, userDescription?.id)}
                              onClick={() => setCondidateDropped(true)}
                            >
                              Mark as In-Active
                            </button>
                          )}
                      </>
                    ) : (
                      <>
                        {checkValidateAccess(umsAccessCode?.mark_as_active) && (
                          <button
                            className={style.active_btn}
                            disabled={disabled}
                            onClick={() =>
                              MarkasChange({
                                employeeId: employeeId,
                                lastWorkingDate: null,
                                userId: userDescription?.id,
                              })
                            }
                          >
                            Mark as Active
                          </button>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className={style.container1}
                    style={{ marginBottom: "1rem" }}
                  >
                    {userDescription?.resignStatus ? (
                      <>
                        {checkValidateAccess(
                          umsAccessCode?.mark_as_un_resigned
                        ) && (
                            <button
                              className={style.delete_btn1}
                              disabled={disabledResign}
                              onClick={() => changeStatusPopup1()}
                            >
                              Mark as Un-Resigned
                            </button>
                          )}
                      </>
                    ) : (
                      <>
                        {checkValidateAccess(umsAccessCode?.mark_as_resign) && (
                          <button
                            className={style.inActive_btn}
                            onClick={() => changeStatusPopup()}
                          >
                            Mark as Resign
                          </button>
                        )}
                      </>
                    )}
                    {checkValidateAccess(umsAccessCode?.add_confirmation) && (
                      <button
                        className={style.inActive_btn}
                        onClick={() => {
                          setAddCanfirmation(true);
                        }}
                      >
                        Add Confirmation
                      </button>
                    )}
                    {checkValidateAccess(umsAccessCode?.mark_as_retain) && (
                      <button
                        className={style.inActive_btn}
                        onClick={() => {
                          setRetainModal(true);
                        }}
                      >
                        Mark as Retain
                      </button>
                    )}
                    {checkValidateAccess(umsAccessCode?.assign_client) && (
                      <button
                        className={style.assignClient}
                        onClick={() => {
                          setAssignClientShow(true);
                        }}
                      >
                        Assign Client
                      </button>
                    )}
                    {checkValidateAccess(umsAccessCode?.assign_team) && (
                      <button
                        className={style.assignClient}
                        onClick={() => {
                          setAssignTeamShow(true);
                        }}
                      >
                        Assign Team
                      </button>
                    )}
                  </div>
                </>
              )}
              <ConfirmPopup open={showSalaryNotesModal}>
                <div className={style.containerNotes}>
                  <div className={style.content}>
                    <p style={{ whiteSpace: "pre-line" }}>{salaryNote ? salaryNote : "No note found"}</p>
                  </div>
                  <div className={style.btnNotes}>
                    <ATSButton onClick={() => setShowSalaryNotesModal(false)}>Done</ATSButton>
                  </div>
                </div>
              </ConfirmPopup>
              <ConfirmPopup open={assignTeamShow}>
                <div className={style.confirmPopup}>
                  <h5
                    className={style.confirmPopupTitle1}
                    style={{ marginLeft: "-18px" }}
                  >
                    Assign Team<span style={{ color: "red" }}>*</span>
                  </h5>

                  <div style={{ width: "76%", marginLeft: "45px" }}>
                    {teamId === undefined || teamId === null ? (
                      <span className={style.cp_serchIcon}>
                        <img src={dropdownIcon} alt="dropdownIcon" />
                      </span>
                    ) : (
                      <span
                        className={style.cp_crossIcon}
                        onClick={() => {
                          setTeamId(null);
                        }}
                      >
                        <img src={cancel} alt="cancel" />
                      </span>
                    )}
                    <Select
                      styles={customStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      value={
                        teamId
                          ? teamOption?.find((e) => e?.value === teamId)
                          : null
                      }
                      onChange={(e) => setTeamId(e.value)}
                      options={teamOption}
                    />
                    <p
                      className={
                        error && !teamId ? style.errorText : style.errorTextHidden
                      }
                    >
                      &nbsp; Please Select Assign Team
                    </p>
                  </div>

                  <div className={style.btnCont} style={{ marginTop: "1rem" }}>
                    <button
                      className={style.delete_btn}
                      onClick={() => {
                        handleTeam();
                      }}
                    >
                      Assign
                    </button>
                    <button
                      className={style.delete_btn}
                      onClick={() => {
                        setAssignTeamShow(false);
                        setError(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ConfirmPopup>
              <ConfirmPopup open={assignClientShow}>
                <div className={style.confirmPopup}>
                  <h5
                    className={style.confirmPopupTitle1}
                    style={{ marginLeft: "-18px" }}
                  >
                    Assign Client
                    {/* <span style={{ color: 'red' }}>*</span> */}
                  </h5>

                  <div style={{ width: "76%", marginLeft: "45px" }}>
                    {clientId === undefined || clientId === null ? (
                      <span className={style.cp_serchIcon}>
                        <img src={dropdownIcon} alt="dropdownIcon" />
                      </span>
                    ) : (
                      <span
                        className={style.cp_crossIcon}
                        onClick={() => {
                          setClientId(null);
                        }}
                      >
                        <img src={cancel} alt="cancel" />
                      </span>
                    )}
                    <Select
                      styles={customStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      value={
                        clientId
                          ? clientOption?.find((e) => e?.value === clientId)
                          : null
                      }
                      onChange={(e) => setClientId(e.value)}
                      options={clientOption}
                    />
                    <p
                      className={
                        validate && !clientId ? style.errorText : style.errorTextHidden
                      }
                    >
                      &nbsp; Please Select Client
                    </p>
                  </div>

                  <div className={style.btnCont} style={{ marginTop: "2rem" }}>
                    <button
                      className={style.delete_btn}
                      onClick={() => {
                        // if (!clientId) {
                        //   setValidate(true)
                        //   return
                        // };
                        assignClientMutation?.mutate({
                          clientId,
                          empId: id,
                        });
                      }}
                    >
                      Assign
                    </button>
                    <button
                      className={style.delete_btn}
                      onClick={() => setAssignClientShow(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ConfirmPopup>
              <ConfirmPopup open={addCanfirmation}>
                <div className={style.confirmPopup1}>
                  <h5 className={style.exitTitle}>
                    Confirmation <span style={{ color: "red" }}>*</span>
                  </h5>
                  <div style={{ width: "76%", marginLeft: "45px" }}>
                    <Select
                      styles={customStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => handleConfimation(e)}
                      options={canfirmationOption?.sort(
                        (a, b) => a?.value - b?.value
                      )}
                    />
                  </div>
                  <p
                    className={
                      errorCanfirmation && !confirmationData?.periodId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;Please Select Confirmation
                  </p>
                  <h5 className={style.exitTitle} style={{ paddingTop: "0rem" }}>
                    Confirmation Date
                  </h5>
                  <div
                    className={style.csd}
                    style={
                      disabledConfirmationDate(
                        confirmationData?.periodId,
                        "confimationDate"
                      )
                        ? {
                          background: "#8f8b8b69",
                          marginLeft: "49px",
                          width: "74%",
                        }
                        : {
                          marginLeft: "49px",
                          width: "74%",
                        }
                    }
                  >
                    <DatePicker
                      dateFormat="dd MMM yy"
                      selected={
                        confirmationData?.confirmationDate
                          ? moment(confirmationData?.confirmationDate).toDate()
                          : null
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      disabled={disabledConfirmationDate(
                        confirmationData?.periodId,
                        "confimationDate"
                      )}
                      dropdownMode="select"
                      startDate={null}
                      onChange={(date) =>
                        setConfirmationData((prevData) => ({
                          ...prevData,
                          confirmationDate: date,
                        }))
                      }
                      defaultValue={null}
                      customInput={<CustomInputClg />}
                      style={{ backgroundColor: "#F5F6FA" }}
                    />
                  </div>

                  <h5
                    className={style.exitTitle}
                    style={{ paddingTop: "1.1rem" }}
                  >
                    Actual Confimation Date
                  </h5>
                  <div
                    className={style.csd}
                    style={
                      disabledConfirmationDate(confirmationData?.periodId)
                        ? {
                          background: "#8f8b8b69",
                          marginLeft: "49px",
                          width: "74%",
                        }
                        : {
                          marginLeft: "49px",
                          width: "74%",
                        }
                    }
                  >
                    <DatePicker
                      dateFormat="dd MMM yy"
                      // minDate={new Date()}
                      selected={
                        confirmationData?.actualConfirmationDate
                          ? moment(
                            confirmationData?.actualConfirmationDate
                          ).toDate()
                          : null
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      disabled={disabledConfirmationDate(
                        confirmationData?.periodId
                      )}
                      dropdownMode="select"
                      startDate={null}
                      onChange={(date) =>
                        setConfirmationData((prevData) => ({
                          ...prevData,
                          actualConfirmationDate: date,
                        }))
                      }
                      defaultValue={null}
                      customInput={<CustomInputClg />}
                      style={{ backgroundColor: "#F5F6FA" }}
                    />
                  </div>
                  <h5
                    className={style.exitTitle}
                    style={{ paddingTop: "1.2rem" }}
                  >
                    Remarks
                  </h5>
                  <div style={{ marginLeft: "49px" }}>
                    <textarea
                      type="textarea"
                      disabled={disabledConfirmationDate(
                        confirmationData?.periodId
                      )}
                      className={style.textarea}
                      onChange={(e) => {
                        setConfirmationData((prev) => ({
                          ...prev,
                          delayReason: e.target?.value,
                        }));
                      }}
                    />
                  </div>

                  <div className={style.btnCont} style={{ marginBottom: "26px" }}>
                    <button
                      className={style.mark_btn}
                      onClick={() => handleConfirmationDate()}
                    >
                      Confirmation
                    </button>
                    <button
                      className={style.delete_btn}
                      onClick={() => {
                        setAddCanfirmation(false);
                        setErrorCanfirmation(false);
                        setConfirmationData((prev) => ({
                          ...prev,
                          actualConfirmationDate: null,
                          confirmationDate: null,
                          delayReason: null,
                          periodId: null,
                        }));
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ConfirmPopup>
              <ConfirmPopup open={retainModal}>
                <div className={style.confirmPopup1} style={{ height: "260px" }}>
                  <h5 className={style.exitTitle}>
                    Retain <span style={{ color: "red" }}>*</span>
                  </h5>
                  <div style={{ width: "273px", marginLeft: "45px" }}>
                    <Select
                      styles={customStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) =>
                        setRetain((prev) => ({ ...prev, retain: e?.value }))
                      }
                      options={retainOption}
                    />
                  </div>
                  <p
                    className={
                      errorRetain && errorMess()
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;Please Select Retain
                  </p>

                  <h5 className={style.exitTitle} style={{ paddingTop: "0px" }}>
                    Date <span style={{ color: "red" }}>*</span>
                  </h5>
                  <div className={style.csd} style={{ marginLeft: "49px" }}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      // minDate={new Date()}
                      minDate={userDescription?.resignationDate ? moment(userDescription.resignationDate).toDate() : null}
                      selected={
                        retain?.retainDate
                          ? moment(retain?.retainDate).toDate()
                          : null
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      // startDate={null}
                      onChange={(date) =>
                        setRetain((prevData) => ({
                          ...prevData,
                          retainDate: date,
                        }))
                      }
                      defaultValue={null}
                      customInput={<CustomInputClg />}
                      style={{ backgroundColor: "#F5F6FA" }}
                    />
                  </div>
                  <p
                    className={
                      errorRetain && !retain?.retainDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Please Select Date
                  </p>

                  <div className={style.btnCont} style={{ marginTop: "2px" }}>
                    <button
                      className={style.mark_btn}
                      onClick={() => handleReatain()}
                    >
                      Mark as Retain
                    </button>
                    <button
                      className={style.delete_btn}
                      onClick={() => {
                        setRetainModal(false);
                        setRetain((prev) => ({
                          ...prev,
                          retain: "",
                          retainDate: null,
                        }));
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ConfirmPopup>
              <ConfirmPopup open={exitModal}>
                <div className={style.confirmPopup1}>
                  <h5 className={style.exitTitle}>
                    Exit Decision Date{" "}
                    {!userDescription?.exitDecisionDate &&
                      !userDescription?.exitPlanDate && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                  </h5>
                  <div className={style.csd} style={{ marginLeft: "49px" }}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      // minDate={new Date()}
                      selected={
                        markAsExitData?.exitDecisionDate
                          ? moment(markAsExitData?.exitDecisionDate).toDate()
                          : null
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      startDate={null}
                      onChange={(date) =>
                        setMarkAsExit((prevData) => ({
                          ...prevData,
                          exitDecisionDate: date,
                        }))
                      }
                      defaultValue={null}
                      customInput={
                        markAsExitData?.exitDecisionDate ? (
                          <CustomInputCross properties="exitDecisionDate" />
                        ) : (
                          <CustomInputClg />
                        )
                      }
                      style={{ backgroundColor: "#F5F6FA" }}
                    />
                  </div>
                  <p
                    className={
                      error &&
                        !userDescription?.exitDecisionDate &&
                        !markAsExitData?.exitDecisionDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Select
                    Exit Decision Date
                  </p>
                  <h5 className={style.exitTitle} style={{ paddingTop: "0px" }}>
                    Exit Planned Date
                  </h5>
                  <div className={style.csd} style={{ marginLeft: "49px" }}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      // minDate={new Date()}
                      selected={
                        markAsExitData?.exitPlanDate
                          ? moment(markAsExitData?.exitPlanDate).toDate()
                          : null
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      startDate={null}
                      onChange={(date) =>
                        setMarkAsExit((prevData) => ({
                          ...prevData,
                          exitPlanDate: date,
                        }))
                      }
                      defaultValue={null}
                      customInput={
                        markAsExitData?.exitPlanDate ? (
                          <CustomInputCross properties="exitPlanDate" />
                        ) : (
                          <CustomInputClg />
                        )
                      }
                      style={{ backgroundColor: "#F5F6FA" }}
                    />
                  </div>
                  <h5 className={style.exitTitle} style={{ paddingTop: "1rem" }}>
                    Exit Note
                  </h5>
                  <div style={{ marginLeft: "49px", marginRight: "1.5rem" }}>
                    <textarea
                      type="textarea"
                      value={markAsExitData?.exitComments}
                      className={style.textarea}
                      onChange={(e) => {
                        setMarkAsExit((prevData) => ({
                          ...prevData,
                          exitComments: e?.target.value,
                        }));
                      }}
                    />
                  </div>

                  <div
                    className={style.btnCont}
                    style={{ paddingBottom: "2rem" }}
                  >
                    <button
                      className={style.mark_btn}
                      onClick={() => handleMarkAsExit()}
                    >
                      Mark as Exit Planned
                    </button>
                    <button
                      className={style.delete_btn}
                      onClick={() => {
                        setError(false);
                        setExitModal(false);
                        setMarkAsExit((prevData) => ({
                          ...prevData,
                          exitPlanDate: "",
                          exitDecisionDate: "",
                        }));
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ConfirmPopup>
              <ConfirmPopup open={leadModal}>
                <div className={style.confirmPopupLead}>
                  <h5 className={style.confirmPopupTitleLead}>
                    Assign Lead <span style={{ color: "red" }}>*</span>
                  </h5>
                  <div style={{ width: "330px", margin: "0px auto" }}>
                    <Select
                      styles={customStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) =>
                        setFormData({ ...formData, leadEmpId: e.value })
                      }
                      options={AllUsersList}
                    />

                    <p
                      className={
                        validate && !formData?.leadEmpId
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Assign Lead
                    </p>
                  </div>
                  <div className={style.btnCont}>
                    <button
                      className={style.unResigned}
                      onClick={() => AssignLeadMethod()}
                    >
                      Assign Lead
                    </button>
                    <button
                      className={style.delete_btn}
                      onClick={() => {
                        setLeadModal(false);
                        setValidate(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ConfirmPopup>
              <ConfirmPopup open={confirmModal}>
                <div className={style.confirmPopup}>
                  <h5 className={style.confirmPopupTitle1}>
                    Resign Date <span style={{ color: "red" }}>*</span>
                  </h5>
                  <div className={style.csd} style={{ marginLeft: "60px" }}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      minDate={new Date(userDescription?.dateOfJoining)}
                      selected={resignedDate}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      startDate={null}
                      onChange={(date) => setResignedDate(date)}
                      defaultValue={null}
                      customInput={<CustomInputClg />}
                      style={{ backgroundColor: "#F5F6FA" }}
                    />
                  </div>
                  <p
                    className={
                      error && !resignedDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                    style={{ paddingLeft: "3.5rem" }}
                  >
                    Please
                    Select Resign Date
                  </p>

                  <div className={style.btnCont}>
                    <button
                      className={style.delete_btn}
                      onClick={() =>
                        changeResignedStatus(userDescription?.id, "resigne")
                      }
                    >
                      Resign
                    </button>
                    <button
                      className={style.delete_btn}
                      onClick={() => handleConfirmPopupClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ConfirmPopup>
              <ConfirmPopup open={UnconfirmModal}>
                <div className={style.confirmPopup}>
                  <h5 className={style.confirmPopupTitle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Un-Resigned
                    Date
                  </h5>
                  <div className={style.csd} style={{ marginLeft: "60px" }}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      // minDate={new Date()}
                      selected={resignedDate}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      startDate={null}
                      onChange={(date) => setResignedDate(date)}
                      defaultValue={null}
                      customInput={<CustomInputClg />}
                      style={{ backgroundColor: "#F5F6FA" }}
                    />
                  </div>

                  <p
                    className={
                      error && !resignedDate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Please Select Un-Resigned Date
                  </p>
                  <div className={style.btnCont}>
                    <button
                      className={style.unResigned}
                      onClick={() =>
                        changeResignedStatus(userDescription?.id, "unResigned")
                      }
                    >
                      Un-Resigned
                    </button>
                    <button
                      className={style.delete_btn}
                      onClick={() => changeOnCancel()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ConfirmPopup>
            </div>
          </>)}
        </div>
      </div>
    </>
  );
};

export default ViewUser;
