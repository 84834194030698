import React, { useState, useEffect } from "react";
import style from "./dashboard.module.scss";
import cookie from "react-cookies";
import Header from "./header/header";
import DashboardImage from "./../../assets/dashboard@3.png";
import axios from "axios";
import { useHistory } from "react-router-dom";
import constants, { localStorageSetItem } from "../../Utils/constants";
import secureLocalStorage from "react-secure-storage";
import { employeeId } from "../../Utils/constants";
import { path } from "../../routes/PathNames";



export default function Dashboard() {
  const history = useHistory();
  const [empAccess, SetEmpAccess] = useState([]);
  const [empAccess1, SetEmpAccess1] = useState();

  function processJSONObjects(jsonArray) {
    const cacheKey = JSON.stringify(jsonArray);
    const data = secureLocalStorage?.getItem("cacheKey");
    if (data === cacheKey) {
      SetEmpAccess(jsonArray);
      return;
    }
    SetEmpAccess(jsonArray);
    return secureLocalStorage?.setItem("cacheKey", jsonArray);
  }

  const baseUrl = constants.apiBaseUrl;
  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;
  const getAccess = () => {
    axios
      .get(url + `/getUserAccess?empId=${employeeId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        SetEmpAccess1(res.data.responseData.roles);
        // console.log("hello signin", res.data.responseData.roles)
        localStorageSetItem("roles", res.data.responseData.roles);
      })
      .catch((err) => {
        return err;
      });
  };
  const getUserInfo = () => {
    axios
      .get(baseUrl + `/getUserApplications?userId=${employeeId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        let data = res.data.responseData.applications;
        processJSONObjects(data?.map((item) => item.applicationCode));
      })
      .catch((err) => {
        return err;
      });
  };
  useEffect(() => {
    getUserInfo();
    getAccess();
  }, []);
  const HandleHrHelpDesk = () => {
    if (empAccess1?.some((item) => item.id === 9 || item.id === 1)) {
      history.push("/SummaryDetails");
    } else if (empAccess1?.length === 0) {
      history.push("/MyIssue");
    }
    return null;
  };
  const handleQuestionnaire = () => {
    history.push("/QuestionList");
  };
  const profile = cookie.load("profile");
  const handleTaskTracker = () => {
    history.push("/taskTracker");
  };

  const handleResourceMaster = () => {
    history.push("/ResourceMaster");
  };

  const handleAtsDashboard = () => {
    history.push("/ats-navigation");
  };
  const handleUMDashboard = () => {
    history.push(path.ums_dashboard);
  };

  const handleExpInvDashboard = () => {
    history.push("/exin-navigation");
  };
  const handleExpInductionDashboard = () => {
    history.push("/Employee-Induction");
  };
  const handleClientContract = () => {
    history.push(path.cc_list);
  };
  const handlePRA = () => {
    history.push(path.revision_dues);
  };
  const handleAppraisalSystem = () => {
    history.push(path.ASDashboard);
  };
  const handleExitFormailitiesDashboard = () => {
    history.push(path.ef_dashboard);
  };
  const handlePCM = () => {
    history.push(path.pcm_dashboard);
  };
  return (
    <>
      <Header />
      <div className={style.container}>
        <div className={style.topHeading}></div>
        <section className={style.welcomeContainer}>
          <h1 className={style.welcomeHeader}>Hi, {profile.name}</h1>
          <p className={style.welcomePara}>
            Get all the information regarding your tasks, projects, resources
            and assets at a single platform.
          </p>
          <img
            alt="DashBoardImage"
            src={DashboardImage}
            className={style.dashboardImage}
          />
          <div className={style.firstCircle}></div>
          <div className={style.secondCircle}></div>
        </section>
        <section className={style.dashboardOptions}>
          {/* task tracker */}
          {empAccess?.includes("TT") ? (
            <div className={style.taskTracker} onClick={handleTaskTracker}>
              <p className={style.optionsText}>Task Tracker</p>
            </div>
          ) : null}
          {/* rms dashboard */}
          {empAccess?.includes("RMS") ? (
            <div
              className={style.projectResource}
              onClick={handleResourceMaster}
            >
              <p className={style.optionsText}>
                Resource Management <br />
                System ( RMS )
              </p>
            </div>
          ) : null}
          {/* User Management dashboard */}
          {empAccess?.includes("UMS") ? (
            <div className={style.userMDashboard} onClick={handleUMDashboard}>
              <p className={style.optionsText}>
                User Management <br />
                System (UMS)
              </p>
            </div>
          ) : null}
          {/* ats dashboard */}
          {empAccess?.includes("ATS") ? (
            <div className={style.atsDashboard} onClick={handleAtsDashboard}>
              <p className={style.optionsText}>
                Applicant Tracking System <br />
                (ATS)
              </p>
            </div>
          ) : null}

          {/* epr */}
          {empAccess?.includes("EPR") ? (
            <div
              className={style.erpDashboard}
              onClick={() => history.push("/EmployeePerformanceReview")}
            >
              <p className={style.optionsText}>PMS</p>
            </div>
          ) : null}

          {empAccess?.includes("FE") ? (
            <div
              className={style.expInvDashboard}
              onClick={handleExpInvDashboard}
              style={{ cursor: "pointer" }}
            >
              <p className={style.optionsText}>Expense & Invoice</p>
            </div>
          ) : null}
          {empAccess?.includes("IND") ? (
            <div
              className={style.expInvDashboard}
              onClick={handleExpInductionDashboard}
              style={{ cursor: "pointer" }}
            >
              <p className={style.optionsText}>Employee Induction</p>
            </div>
          ) : null}

          {empAccess?.includes("TS") ? (
            <div
              className={style.etsDashboard}
              onClick={() => history.push("/EmpolyeeTimeSheet")}
            >
              <p className={style.optionsText}>Employee Timesheet</p>
            </div>
          ) : null}

          {empAccess?.includes("PM") ? (
            <div
              className={style.partnerDashboard}
              onClick={() => history.push(path.portalManagement)}
            >
              <p className={style.optionsText}>Portal Master</p>
            </div>
          ) : null}

          {empAccess?.includes("HRHD") ? (
            <div
              className={style.hrHelpDeskDashBoard}
              onClick={HandleHrHelpDesk}
              style={{ cursor: "pointer" }}
            >
              <div>
                {/* <img src={ats} alt="HrHelpDesk" className={style.optionsImage} /> */}
              </div>
              <p className={style.optionsText}>HR Help Desk</p>
            </div>
          ) : null}

          {empAccess?.includes("QUES") ? (
            <div
              className={style.erpDashboard}
              onClick={handleQuestionnaire}
              style={{ cursor: "pointer" }}
            >
              <div></div>
              <p className={style.optionsText}>Client Questionnaire</p>
            </div>
          ) : null}
          {empAccess?.includes("CC") ? (
            <div
              className={style.CCDashBoard}
              onClick={handleClientContract}
              style={{ cursor: "pointer" }}
            >
              <div></div>
              <p className={style.optionsText}>Client Contract</p>
            </div>
          ) : null}

          {empAccess?.includes("PRV") ? (
            <div
              className={style.PRADashBoard}
              onClick={handlePRA}
              style={{ cursor: "pointer" }}
            >
              <div></div>
              <p className={style.optionsText}>Client Rates</p>
            </div>
          ) : null}
          {empAccess?.includes("AS") ? (
            <div
              className={style.CCDashBoard}
              onClick={handleAppraisalSystem}
              style={{ cursor: "pointer" }}
            >
              <div></div>
              <p className={style.optionsText}>Performance Appraisal</p>
            </div>
          ) : null}
          {empAccess?.includes("EF") ? (
            <div
              className={style.exitFormalityDashboard}
              onClick={handleExitFormailitiesDashboard}
              onKeyDown={handleExitFormailitiesDashboard}
              tabIndex={0}
              style={{ cursor: "pointer" }}
            >
              <p className={style.optionsText}>Employee Offboarding</p>
            </div>
          ) : null}
          {empAccess?.includes("PCM") ? (
            <div
              className={style.pcmDashboard}
              onClick={handlePCM}
              style={{ cursor: "pointer" }}
            >
              <p className={style.optionsText}>Project Costing & Margin</p>
            </div>
          ) : null}
        </section>
      </div>
    </>
  );
}
