import React from "react";
import style from "./SideBar.module.css";
import three from "../../../assets/Project_candidate_profile_white.png";
import four from "../../../assets/Proejct_Candidate_allocation_white.png";
import threeG from "../../../assets/Project_candidate_profile_green.png";
import fourG from "../../../assets/Project_Candidate_Allocation_Green.png";
import ats from "./../../../assets/ats.png";

import orp from "../../../assets/Project_Offer_release_process_white.png";
import orpg from "../../../assets/Project_Offer_release_process_Green.png";
import iv from "../../../assets/Project_my_interview_white.png";
import ivg from "../../../assets/Project_interview_green.png";
import jpp from "../../../assets/Project_joining_process_green.png";
import jppg from "../../../assets/Project_joining_process_white.png";
import cm from "../../../assets/Proejct_Client_Management_white.png";
import cmg from "../../../assets/Project_Client_management_Green.png";

import cag from "../../../assets/Project_Client_deployment_green.png";
import caw from "../../../assets/Project_client_deployment_white.png";
import icon_green from "../../../assets/Project_Candidate_Journey_green.png";
import icon_white from "../../../assets/Proejct_Candidate_Journey_white.png";
import Project_BGV_white from "../../../assets/Project_BGV_white.png";
import Project_BGV_green from "../../../assets/Project_BGV_Green.png";
import black_listed_green from "../../../assets/blacklistCompany.png";
import black_listed_white from "../../../assets/blacklistCompanyWhite.png";
import Project_report_green from "../../../assets/Project_report_green.png";
import Project_report_white from "../../../assets/Proejct_report_white.png";
import Project_resourceAvailability_green from "../../../assets/resource_avail_white.svg";
import Project_resourceAvailability_white from "../../../assets/resource_avail_green.svg";
import Project_position_allocation_white from "../../../assets/Project_position_allocation_white.png";
import Project_Position_Allocation_green from "../../../assets/Project_Position_Allocation_green.png";
import duplicity_green from "../../../assets/duplicity_green.png";
import duplicity_white from "../../../assets/duplicity_white.png";

import Project_position_management_white from "../../../assets/Project_position_management_white.png";
import Project_project_allocation_green from "../../../assets/Project_project_allocation_green.png";
import dashboard_white from "../../../assets/Dashwhite.png"
import dashboard_green from "../../../assets/Dashgreen.png"
import knowledge_base_white from "../../../assets/book-open-icon (1) 1.svg";
import knowledge_base_green from "../../../assets/book-open-icon (1) 2.svg";
import watchlist_white from "../../../assets/eye-monitoring-icon 1.svg";
import watchlist_green from "../../../assets/eye-monitoring-icon 2.svg";

import InnovationMLogo from "../../../assets/Grievance portal/image-innovationm-logo@2x.png";
import { useHistory } from "react-router";
import ValidationEmpAccess from "../pageLayout/validationEmpAccess/ValidationEmpAccess";
import { accessCode, employeeRole } from "../../../Utils/constants";
import { BsFillDatabaseFill } from "react-icons/bs";
import { MdPersonSearch } from "react-icons/md";

const SideBar = ({
  sidebarHandler,
  setPrevData,
  prevData,
  setCurrentPage,
  menuAccess,
  roles
}) => {
  let history = useHistory();

  return (
    <div className={style.leftSection}>
      {/* 0 */}
      <img
        alt="DashBoardImage"
        src={InnovationMLogo}
        className={style.dashboardImage}
        onClick={() => history.push("/dashboard")}
      />
      <button className={style.taskTracker}>
        <img src={ats} alt="img" height="40px" />
        <span>ATS Management</span>
      </button>
      {/* 1 */}

      {roles.includes(3) || roles.includes(4) ?
        <button
          onClick={() => {
            sidebarHandler("recruiterDashBoard");
            setCurrentPage(1);
            setPrevData({
              ...prevData,
              sidebar: "recruiterDashBoard",
              page: "recruiterDashBoard",
              page1: "recruiterDashBoard",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "recruiterDashBoard",
              name: "",
              positionId: null,
            });
          }}
          className={
            prevData?.sidebar === "recruiterDashBoard"
              ? style.selected
              : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData?.sidebar === "recruiterDashBoard" ? (
              <img src={dashboard_green} alt="img" />
            ) : (
              <img src={dashboard_white} alt="img" />
            )}
          </div>
          <div className={`${style.sb}`}>
            <span>Recruiter DashBoard</span>
          </div>
        </button>
        : null
      }
      <button
        onClick={() => {
          sidebarHandler("Watchlist");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "Watchlist",
            page: "Watchlist",
            page1: "Watchlist",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "Watchlist",
            name: "",
            positionId: null,
          });
        }}
        className={
          prevData?.sidebar === "Watchlist"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "Watchlist" ? (
            <img src={watchlist_green} alt="img" />
          ) : (
            <img src={watchlist_white} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Watchlist</span>
        </div>
      </button>
      <button
        onClick={() => {
          sidebarHandler("Menu");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "Menu",
            page: "Menu",
            page1: "Menu",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "Menu",
            name: "",
            positionId: null,
          });
        }}
        className={
          prevData?.sidebar === "Menu"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "Menu" ? (
            <img src={knowledge_base_green} alt="img" />
          ) : (
            <img src={knowledge_base_white} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Knowledge Base</span>
        </div>
      </button>
      {ValidationEmpAccess(menuAccess, accessCode?.my_interviewers_menu) && (
        <button
          onClick={() => {
            sidebarHandler("myInterviews");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "myInterviews",
              page1: "myInterviews",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "myInterviews",
              name: "",
              positionId: null,
            }));
          }}
          className={
            prevData.sidebar === "myInterviews"
              ? style.selected
              : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "myInterviews" ? (
              <img src={ivg} alt="img" width="30px" />
            ) : (
              <img src={iv} alt="img" width="30px" />
            )}
          </div>
          <div className={style.sb}>
            <span>My Interviews</span>
          </div>
        </button>
      )}

      {ValidationEmpAccess(
        menuAccess,
        accessCode?.position_management_menu
      ) && (
          <button
            onClick={() => {
              sidebarHandler("positionList");
              setCurrentPage(1);
              setPrevData({
                ...prevData,
                sidebar: "positionList",
                page: "positionList",
                page1: "positionList",
                page2: null,
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                active: "positionList",
                name: "",
                positionId: null,
              });
            }}
            className={
              prevData?.sidebar === "positionList"
                ? style.selected
                : style.assignedByMe
            }
          >
            <div className={style.sb0}>
              {prevData?.sidebar === "positionList" ? (
                <img src={Project_project_allocation_green} alt="img" />
              ) : (
                <img src={Project_position_management_white} alt="img" />
              )}
            </div>
            <div className={style.sb}>
              <span>Position Management</span>
            </div>
          </button>
        )}

      {/* 2 */}
      {ValidationEmpAccess(
        menuAccess,
        accessCode?.position_allocation_menu
      ) && (
          <button
            onClick={() => {
              setCurrentPage(1);
              sidebarHandler("CurrentAllocation");
              setPrevData({
                ...prevData,
                sidebar: "CurrentAllocation",
                page: "CurrentAllocation",
                page1: "CurrentAllocation",
                page2: null,
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                active: "CurrentAllocation",
                name: "",
                positionId: null,
              });
            }}
            className={
              prevData?.sidebar === "CurrentAllocation"
                ? style.selected
                : style.assignedByMe
            }
          >
            <div className={style.sb0}>
              {prevData?.sidebar === "CurrentAllocation" ? (
                <img src={Project_Position_Allocation_green} alt="img" />
              ) : (
                <img src={Project_position_allocation_white} alt="img" />
              )}
            </div>

            <div className={style.sb}>
              <span>Position Allocation</span>
            </div>
          </button>
        )}
      {/* 3 */}
      {ValidationEmpAccess(menuAccess, accessCode?.candidate_profile_menu) && (
        <button
          onClick={() => {
            sidebarHandler("candidateList");
            setPrevData((prevData) => ({
              ...prevData,
              sidebar: "candidateList",
              page1: "candidateList",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              active: "candidateList",
              name: "",
              positionId: null,
            }));
          }}
          className={
            prevData.sidebar === "candidateList"
              ? style.selected
              : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "candidateList" ? (
              <img src={threeG} alt="img" />
            ) : (
              <img src={three} alt="img" />
            )}
          </div>
          <div className={style.sb}>
            <span>Candidate Profile</span>
          </div>
        </button>
      )}

      {ValidationEmpAccess(
        menuAccess,
        accessCode?.candidate_allocation_menu
      ) && (
          <button
            onClick={() => {
              sidebarHandler("currentCandidateAllocation");
              setPrevData((prevData) => ({
                ...prevData,
                page: "currentCandidateAllocation",
                sidebar: "currentCandidateAllocation",
                page1: "currentCandidateAllocation",
                page2: null,
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                active: "currentCandidateAllocation",
                name: "",
                positionId: null,
              }));
            }}
            className={
              prevData.sidebar === "currentCandidateAllocation"
                ? style.selected
                : style.assignedByMe
            }
          >
            <div className={style.sb0}>
              {prevData.sidebar === "currentCandidateAllocation" ? (
                <img src={fourG} alt="img" />
              ) : (
                <img src={four} alt="img" />
              )}
            </div>

            <div className={style.sb}>
              <span>Candidate Allocation</span>
            </div>
          </button>
        )}

      {ValidationEmpAccess(menuAccess, accessCode?.my_interview_menu) && (
        <button
          onClick={() => {
            sidebarHandler("myInterviewList");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "myInterviewList",
              page1: "myInterviewList",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "myInterviewList",
              name: "",
              positionId: null,
            }));
          }}
          className={
            prevData.sidebar === "myInterviewList"
              ? style.selected
              : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "myInterviewList" ? (
              <img src={ivg} alt="img" width="30px" />
            ) : (
              <img src={iv} alt="img" width="30px" />
            )}
          </div>
          <div className={style.sb}>
            <span>Interview Assessments</span>
          </div>
        </button>
      )}

      {/* {ValidationEmpAccess(
        menuAccess,
        accessCode?.offer_release_process_menu
      ) && (
          <button
            onClick={() => {
              sidebarHandler("myCandidateOfferProcess");
              setCurrentPage(1);
              setPrevData({
                ...prevData,
                sidebar: "myCandidateOfferProcess",
                page: "myCandidateOfferProcess",
                page1: "myCandidateOfferProcess",
                page2: null,
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                active: "myCandidateOfferProcess",
                name: "",
                positionId: null,
              });
            }}
            className={
              prevData?.sidebar === "myCandidateOfferProcess"
                ? style.selected
                : style.assignedByMe
            }
          >
            <div className={style.sb0}>
              {prevData.sidebar === "myCandidateOfferProcess" ? (
                <img src={orpg} alt="img" />
              ) : (
                <img src={orp} alt="img" />
              )}
            </div>
            <div className={style.sb}>
              <span>Offer Process</span>
            </div>
          </button>
        )} */}


      {ValidationEmpAccess(
        menuAccess,
        accessCode?.offer_release_process_menu
      ) && (
          <button
            onClick={() => {
              sidebarHandler("myCandidateFinalStages");
              setCurrentPage(1);
              setPrevData({
                ...prevData,
                sidebar: "myCandidateFinalStages",
                page: "myCandidateFinalStages",
                page1: "myCandidateFinalStages",
                page2: null,
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                active: "myCandidateFinalStages",
                name: "",
                positionId: null,
              });
            }}
            className={
              prevData?.sidebar === "myCandidateFinalStages"
                ? style.selected
                : style.assignedByMe
            }
          >
            <div className={style.sb0}>
              {prevData.sidebar === "myCandidateFinalStages" ? (
                <img src={orpg} alt="img" />
              ) : (
                <img src={orp} alt="img" />
              )}
            </div>
            <div className={style.sb}>
              <span>Final Stages</span>
            </div>
          </button>
        )}
      {/* 8 */}
      {ValidationEmpAccess(menuAccess, accessCode?.joining_process_menu) && (
        <button
          onClick={() => {
            sidebarHandler("myTasks");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "myTasks",
              page1: "myTasks",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "myTasks",
              name: "",
              positionId: null,
            }));
          }}
          className={
            prevData.sidebar === "myTasks" ? style.selected : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "myTasks" ? (
              <img src={jpp} alt="img" width="30px" />
            ) : (
              <img src={jppg} alt="img" width="30px" />
            )}
          </div>
          <div className={style.sb}>
            <span>Joining Process</span>
          </div>
        </button>
      )}
      {/* 9 */}
      {ValidationEmpAccess(menuAccess, accessCode?.client_management_menu) && (
        <button
          onClick={() => {
            sidebarHandler("clients");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "clients",
              page1: "clients",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "clients",
              name: "",
              positionId: null,
            }));
          }}
          className={
            prevData.sidebar === "clients" ? style.selected : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "clients" ? (
              <img src={cmg} alt="img" width="30px" />
            ) : (
              <img src={cm} alt="img" width="30px" />
            )}
          </div>
          <div className={style.sb}>
            <span>Client Management</span>
          </div>
        </button>
      )}
      {/* Partner Management*/}
      {/*<button
            onClick={() => {
              sidebarHandler("partners");
              setPrevData((prevData) => ({
                ...prevData,
                page: "positionALL",
                sidebar: "partners",
                page1: "partners",
                page2: null,
                page3: null,
                page4: null,
                page5: null,
                page6: null,
                active: "partners",
                name: "",
                positionId: null,
              }));
            }}
            className={
              prevData.sidebar === "partners"
                ? style.selected
                : style.assignedByMe
            }
          >
            <div className={style.sb0}>
              {prevData.sidebar === "partners" ? (
                <img src={pmg} alt="img" width="30px" />
              ) : (
                <img src={pm} alt="img" width="30px" />
              )}
            </div>
            <div className={style.sb}>
              <span>Partner Management</span>
            </div>
              </button>*/}

      {/* 11 */}
      {ValidationEmpAccess(menuAccess, accessCode?.client_deployement_menu) && (
        <button
          onClick={() => {
            sidebarHandler("candidateAllocation(Client)");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "candidateAllocation(Client)",
              ListOfProfileInClient: "null",
              page1: "candidateAllocation(Client)",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "candidateAllocation(Client)",
              name: "",
              positionId: null,
            }));
          }}
          className={
            prevData.sidebar === "candidateAllocation(Client)"
              ? style.selected
              : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "candidateAllocation(Client)" ? (
              <img src={cag} alt="img" width="30px" />
            ) : (
              <img src={caw} alt="img" width="30px" />
            )}
          </div>
          <div className={style.sb}>
            <span>Client Deployment</span>
          </div>
        </button>
      )}
      {/* 12 */}

      {/* 13 */}
      {ValidationEmpAccess(menuAccess, accessCode?.candidate_journey_menu) && (
        <button
          onClick={() => {
            sidebarHandler("reportCandidateList");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "reportCandidateList",
              page1: "reportCandidateList",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "reportCandidateList",
              positionId: null,
            }));
          }}
          className={
            prevData.sidebar === "reportCandidateList"
              ? style.selected
              : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "reportCandidateList" ? (
              <img src={icon_green} alt="img" width="30px" />
            ) : (
              <img src={icon_white} alt="img" width="30px" />
            )}
          </div>
          <div className={style.sb}>
            <span>Candidate Journey</span>
          </div>
        </button>
      )}
      {/* Job Applicant */}
      {ValidationEmpAccess(menuAccess, accessCode?.candidate_journey_menu) && (
        <button
          onClick={() => {
            {
              roles && roles?.includes(1) && (prevData?.filterModalJobApplicant === false || prevData?.filterModalJobApplicant === undefined) ? sidebarHandler("jobApplicants") : sidebarHandler("filterModalJobApplicant");
            }
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "jobApplicants",
              page1: "jobApplicants",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "jobApplicants",
              positionId: null,
            }));
          }}
          className={
            prevData.sidebar === "jobApplicants"
              ? style.selected
              : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "jobApplicants" ? (
              <MdPersonSearch style={{ fontSize: '30px' }} />
            ) : (
              <MdPersonSearch style={{ fontSize: '30px' }} />
            )}
          </div>
          <div className={style.sb}>
            <span>Job Applicant</span>
          </div>
        </button>
      )}
      {ValidationEmpAccess(menuAccess, accessCode?.duplicity_check_menu) && (
        <button
          onClick={() => {
            sidebarHandler("duplicityCheck");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "duplicityCheck",
              page1: "duplicityCheck",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "duplicityCheck",
              positionId: null,
            }));
          }}
          className={
            prevData.sidebar === "duplicityCheck"
              ? style.selected
              : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "duplicityCheck" ? (
              <img src={duplicity_green} alt="img" width="30px" />
            ) : (
              <img src={duplicity_white} alt="img" width="30px" />
            )}
          </div>
          <div className={style.sb}>
            <span>Duplicity Check </span>
          </div>
        </button>
      )}
      {/* 14 */}
      {ValidationEmpAccess(menuAccess, accessCode?.report_menu) && (
        <button
          onClick={() => {
            sidebarHandler("ATSDatabase");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "ATSDatabase",
              page1: "ATSDatabase",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "ATSDatabase",
              name: "",
            }));
          }}
          className={
            prevData.sidebar === "ATSDatabase"
              ? style.selected
              : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "ATSDatabase" ? (
              <BsFillDatabaseFill
                style={{ color: "green", fontSize: "1.7rem" }}
                width="50px"
              />
            ) : (
              <BsFillDatabaseFill
                style={{ color: "#fff", fontSize: "1.7rem" }}
                width="50px"
              />
            )}
          </div>
          <div className={style.sb}>
            <span>ATS Database </span>
          </div>
        </button>
      )}
      {
        ValidationEmpAccess(menuAccess, accessCode?.resorce_mapping) && <button
          onClick={() => {
            sidebarHandler("resourceAvailability");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "resourceAvailability",
              page1: "resourceAvailability",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              prevFilterData: null,
              prevFilter: null,
              active: "resourceAvailability",
              name: "",
            }));
          }}
          className={
            prevData.sidebar === "resourceAvailability"
              ? style.selected
              : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "resourceAvailability" ? (
              <img
                src={Project_resourceAvailability_green}
                alt="img"
                width="30px"
              />
            ) : (
              <img
                src={Project_resourceAvailability_white}
                alt="img"
                width="30px"
              />
            )}
          </div>
          <div className={style.sb}>
            <span>Resource Mapping </span>
          </div>
        </button>
      }
      {
        ValidationEmpAccess(menuAccess, accessCode?.position_mapping) && <button
          onClick={() => {
            sidebarHandler("positionMapping");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "positionMapping",
              page1: "positionMapping",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              prevFilterData: null,
              prevFilter: null,
              active: "positionMapping",
              name: "",
            }));
          }}
          className={
            prevData.sidebar === "positionMapping"
              ? style.selected
              : style.assignedByMe
          }

        >
          <div className={style.sb0}>
            {prevData.sidebar === "positionMapping" ? (
              <img
                src={Project_resourceAvailability_green}
                alt="img"
                width="30px"
              />
            ) : (
              <img
                src={Project_resourceAvailability_white}
                alt="img"
                width="30px"
              />
            )}
          </div>
          <div className={style.sb}>
            <span>Position Mapping </span>
          </div>
        </button>
      }
      {/* {ValidationEmpAccess(menuAccess, accessCode?.report_menu) && (
        <button
          onClick={() => {
            sidebarHandler("report");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "report",
              page1: "report",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "report",
              name: "",
            }));
          }}
          className={
            prevData.sidebar === "report" ? style.selected : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "report" ? (
              <img src={Project_report_green} alt="img" width="30px" />
            ) : (
              <img src={Project_report_white} alt="img" width="30px" />
            )}
          </div>
          <div className={style.sb}>
            <span>Report </span>
          </div>
        </button>
      )} */}

      {ValidationEmpAccess(menuAccess, accessCode?.report_menu) && (
        <button
          onClick={() => {
            sidebarHandler("reportNew");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "reportNew",
              page1: "reportNew",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "reportNew",
              name: "",
            }));
          }}
          className={
            prevData.sidebar === "reportNew" ? style.selected : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "reportNew" ? (
              <img src={Project_report_green} alt="img" width="30px" />
            ) : (
              <img src={Project_report_white} alt="img" width="30px" />
            )}
          </div>
          <div className={style.sb}>
            <span>Report </span>
          </div>
        </button>
      )}



      {/*BGV*/}
      {ValidationEmpAccess(menuAccess, accessCode?.bgv_menu) && (
        <button
          onClick={() => {
            sidebarHandler("BGV");
            setPrevData((prevData) => ({
              ...prevData,
              page: "BGV",
              sidebar: "BGV",
              page1: "BGV",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "BGV",
              name: "",
            }));
          }}
          className={
            prevData.sidebar === "BGV" ? style.selected : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "BGV" ? (
              <img src={Project_BGV_green} alt="img" width="30px" />
            ) : (
              <img src={Project_BGV_white} alt="img" width="30px" />
            )}
          </div>
          <div className={style.sb}>
            <span>BGV </span>
          </div>
        </button>
      )}
      {ValidationEmpAccess(menuAccess, accessCode?.bgv_menu) && (
        <button
          onClick={() => {
            sidebarHandler("blacklistedCompany");
            setPrevData((prevData) => ({
              ...prevData,
              page: "blacklistedCompany",
              sidebar: "blacklistedCompany",
              page1: "blacklistedCompany",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "blacklistedCompany",
              name: "",
            }));
          }}
          className={
            prevData.sidebar === "blacklistedCompany" ? style.selected : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData.sidebar === "blacklistedCompany" ? (
              <img src={black_listed_green} alt="img" width="30px" />
            ) : (
              <img src={black_listed_white} alt="img" width="30px" />
            )}
          </div>
          <div className={style.sb}>
            <span>Blacklisted Company </span>
          </div>
        </button>
      )}
    </div>
  );
};

export default SideBar;
